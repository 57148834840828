import React from 'react';
import styled from 'styled-components';
import { colors, fonts, StandardButton, sizes } from '../styles/reusableStyles';
import { useHistory } from 'react-router';

const CTACard = styled.div`
	background: white;
	box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.1);
	border-radius: 12px;
	padding: 24px 16px;
	margin: 0 24px;
	@media screen and (min-width: ${sizes.tablet}){
		width: 80%;
		max-width: 772px;
		margin: auto;
		margin-bottom: 40px;
	}
	margin-bottom: 40px;
	text-align: center;
	max-width: 800px;

	h2 {
		color: ${colors.primaryGreen};
		margin-bottom: 7px;
    }
    
    div{
        margin-bottom : 16px;
        ${fonts.bigBody}
        color: ${colors.smallGreyText};
    }
`;

const ContactUs = styled(StandardButton)`
	background-color: ${colors.primaryGreen};
	border: 2px solid ${colors.primaryGreen};
	box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);
`;

const HaveQuestionsCTA = () => {
	const history = useHistory();
	return (
		<CTACard>
			<h2>Have questions?</h2>
			<div>
				We are here to help. You may reach out via phone, email, contact
				form or in person.
			</div>
			<ContactUs onClick={() => history.push('/contact')}>
				Contact Us
			</ContactUs>
		</CTACard>
	);
};

export default HaveQuestionsCTA;
