import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Input,
  Dimmer,
  Loader,
  Form,
  Menu,
  Icon,
  Table,
} from "semantic-ui-react";
import { StandardButton, fonts, colors } from "../../styles/reusableStyles";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import queries from "../../helpers/queries";
import mutations from "../../helpers/mutations";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useParams,
} from "react-router";
import moment from "moment-timezone";
import {
  AddShiva,
  Shiva,
  MemorialContribution,
  Dropzone,
  ChangeImage,
  uploadfileAndGetUrl,
} from "./Admin";
import audioLogo from "../../media/audiofile.png";
import axios from "axios";

const EditObituarySection = styled.div`
  width: 80%;
  max-width: 772px;
  margin: auto;
  margin-bottom: 40px;
  h2 {
    margin-bottom: 24px;
  }
  .big-height {
    textarea {
      height: 300px;
    }
  }
`;

const EditSingleObituary = () => {
  const { id } = useParams();
  const getObituaryQuery = useQuery(queries.getObituaryById, {
    variables: {
      id,
    },
  });
  const obituary =
    getObituaryQuery.data && getObituaryQuery.data.getObituaryById;

  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [deathDate, setDeathDate] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [description, setDescription] = useState("");
  const [cemeteryName, setCemeteryName] = useState("");
  const [cemeteryAddress, setCemeteryAddress] = useState("");
  const [streamingLink, setStreamingLink] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [audioFilePreview, setAudioFilePreview] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [useMenorahImage, setUseMenorahImage] = useState(false);

  const [serviceType, setServiceType] = useState("");
  const [serviceAddress, setServiceAddress] = useState("");
  const [serviceDate, setServiceDate] = useState("");
  const [serviceTime, setServiceTime] = useState("");
  const [privateService, setPrivateService] = useState(false);

  const [shivas, setShivas] = useState([]);
  const [memorialContributions, setMemorialContributions] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);

  const [updateObituary, updateObituaryState] = useMutation(
    mutations.updateObituary
  );

  const loading =
    !getObituaryQuery.called ||
    getObituaryQuery.loading ||
    updateObituaryState.loading;

  const changeImage = () => {
    setPreview("");
    setImage(null);
  };

  const changeAudioFile = () => {
    setAudioFile(null);
    setAudioFilePreview("");
  };

  const handleSubmit = async () => {
    if ((serviceDate || serviceTime) && !(serviceDate && serviceTime)) {
      alert("If you enter a service date, you must also enter a service time");
      return;
    }
    let imageUrl;
    let audioFileUrl;

    if (image) {
      imageUrl = await uploadfileAndGetUrl(image);
    }

    if (audioFile) {
      audioFileUrl = await uploadfileAndGetUrl(audioFile);
    }
    updateObituary({
      variables: {
        input: {
          id: obituary.id,
          name,
          birthDate,
          deathDate,
          excerpt,
          description,
          cemeteryName,
          cemeteryAddress,
          streamingLink,
          audioFile: audioFileUrl,
          deleteAudio: !audioFile && !audioFilePreview,
          image: imageUrl,
          deleteImage: !image && !preview,
          useMenorahImage,
          visible,
          sendEmail,
          service: {
            id: obituary.service.id,
            type: serviceType,
            address: serviceAddress,
            date: serviceDate,
            time: serviceTime,
            isPrivate: privateService,
          },
          shivas: shivas.map((s, i) => ({
            ...s,
            index: i,
          })),
          memorialContributions: memorialContributions,
        },
      },
    })
      .then(() => alert("Your changes were successfully saved"))
      .catch(() =>
        alert(
          "There was an error saving your changes, please contact the site administrator."
        )
      );
  };

  const addShiva = (e) => {
    e.preventDefault();
    setShivas([
      ...shivas,
      {
        id: "-1",
        locationName: "",
        address: "",
        date: "",
        startTime: "",
        endTime: "",
      },
    ]);
  };
  const getSortValue = () => {
    if (memorialContributions.length)
      return memorialContributions[memorialContributions.length - 1].sort + 1;
    return 1;
  };

  const addMemorialContribution = (e) => {
    e.preventDefault();
    setMemorialContributions([
      ...memorialContributions,
      {
        id: "-1",
        name: "",
        address: "",
        website: "",
        sort: getSortValue(),
      },
    ]);
  };

  useEffect(() => {
    if (obituary) {
      setVisible(obituary.visible);
      setName(obituary.name);
      setBirthDate(
        obituary.birthDate
          ? moment
              .tz(parseInt(obituary.birthDate), "America/Chicago")
              .format("yyyy-MM-DD")
          : ""
      );
      setDeathDate(
        moment
          .tz(parseInt(obituary.deathDate), "America/Chicago")
          .format("yyyy-MM-DD")
      );
      setExcerpt(obituary.excerpt);
      setDescription(obituary.description);
      setCemeteryAddress(obituary.cemeteryAddress || "");
      setCemeteryName(obituary.cemeteryName || "");
      setStreamingLink(obituary.streamingLink);
      setAudioFilePreview(obituary.audioFileAddress);
      setPreview(obituary.imageAddress);
      setUseMenorahImage(obituary.useMenorahImage);
      setServiceType(obituary.service.type);
      setServiceAddress(obituary.service.address || "");
      setServiceDate(
        obituary.service.date
          ? moment
              .tz(parseInt(obituary.service.date), "America/Chicago")
              .format("yyyy-MM-DD")
          : ""
      );
      setServiceTime(
        obituary.service.date
          ? moment
              .tz(parseInt(obituary.service.date), "America/Chicago")
              .format("HH:mm")
          : ""
      );
      setPrivateService(obituary.service.isPrivate);
      setShivas(
        obituary.shivas
          .map((s) => {
            return {
              id: s.id,
              index: s.index,
              locationName: s.locationName,
              address: s.address,
              date: moment
                .tz(parseInt(s.start), "America/Chicago")
                .format("yyyy-MM-DD"),
              startTime: moment
                .tz(parseInt(s.start), "America/Chicago")
                .format("HH:mm"),
              endTime: s.end
                ? moment.tz(parseInt(s.end), "America/Chicago").format("HH:mm")
                : "",
            };
          })
          .sort((a, b) => a.index - b.index)
      );
      setMemorialContributions(
        obituary.memorialContributions
          .slice()
          .sort((a, b) => a.sort - b.sort)
          .map((m) => {
            return {
              id: m.id,
              name: m.name,
              address: m.address,
              website: m.website,
              sort: m.sort,
            };
          })
      );
    }
  }, [obituary]);

  return (
    <>
      <Dimmer active={loading} />
      <Loader active={loading} />
      <EditObituarySection>
        <Form onSubmit={handleSubmit}>
          <h2>Basic Obituary Info</h2>
          <Form.Checkbox
            label="Publish this obituary"
            checked={visible}
            onChange={() => setVisible(!visible)}
          />
          <Form.Checkbox
            label="Send Email Alert (will only send email if an email has not yet been sent for this obituary)"
            checked={sendEmail}
            onChange={() => setSendEmail(!sendEmail)}
          />
          <Form.Input
            required
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Form.Input
            type="date"
            label="Birth Date"
            placeholder="mm/dd/yyyy"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
          />
          <Form.Input
            required
            type="date"
            label="Death Date"
            placeholder="mm/dd/yyyy"
            value={deathDate}
            onChange={(e) => setDeathDate(e.target.value)}
          />
          <Form.Input
            required
            label="Excerpt"
            value={excerpt}
            onChange={(e) => setExcerpt(e.target.value)}
          />
          <Form.TextArea
            className="big-height"
            required
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Form.Input
            label="Cemetery Name"
            value={cemeteryName}
            onChange={(e) => setCemeteryName(e.target.value)}
          />
          <Form.TextArea
            label="Cemetery Address"
            value={cemeteryAddress}
            onChange={(e) => setCemeteryAddress(e.target.value)}
          />
          <Form.Input
            label="Streaming Link"
            value={streamingLink}
            onChange={(e) => setStreamingLink(e.target.value)}
          />
          <label>Audio Link (mp3)</label>
          <br />
          {(audioFile || audioFilePreview) && (
            <>
              <ChangeImage onClick={changeAudioFile}>
                Change Audio File
              </ChangeImage>
              <br />
            </>
          )}
          <Dropzone
            audio
            setImage={setAudioFile}
            setPreview={setAudioFilePreview}
            path={audioFile || audioFilePreview ? audioLogo : null}
          />
          <br />
          <label>Image (optional)</label>
          <br />
          {preview && (
            <>
              <ChangeImage onClick={changeImage}>Change Image</ChangeImage>
              <br />
            </>
          )}
          <Dropzone
            path={preview}
            setImage={setImage}
            setPreview={setPreview}
          />
          <Form.Checkbox
            label="Use Menorah image if no image provided (otherwise this will show one of the default images)"
            checked={useMenorahImage}
            onChange={() => setUseMenorahImage(!useMenorahImage)}
          />
          <h2>Service Information</h2>
          <Form.TextArea
            required
            label="Service Type"
            value={serviceType}
            onChange={(e) => setServiceType(e.target.value)}
          />
          <Form.TextArea
            label="Service Address"
            value={serviceAddress}
            onChange={(e) => setServiceAddress(e.target.value)}
          />
          <Form.Input
            type="date"
            label="Service Date"
            value={serviceDate}
            onChange={(e) => setServiceDate(e.target.value)}
          />
          <Form.Input
            type="time"
            label="Service Time (Central Time)"
            value={serviceTime}
            onChange={(e) => setServiceTime(e.target.value)}
          />
          <Form.Checkbox
            label="Private Service"
            checked={privateService}
            onChange={() => setPrivateService(!privateService)}
          />
          <h2>Shiva Information</h2>
          <AddShiva onClick={addShiva}>Add Shiva</AddShiva>
          {shivas.map((s, i) => {
            if (!(s.id.length > 2 && s.id.substring(0, 2) == "-2"))
              return (
                <Shiva
                  key={i}
                  shivas={shivas}
                  setShivas={setShivas}
                  index={i}
                  edit
                />
              );
          })}
          <h2>Memorial Contributions</h2>
          <AddShiva onClick={addMemorialContribution}>
            Add Memorial Contribution
          </AddShiva>
          {memorialContributions.map((m, i) => {
            if (!(m.id.length > 2 && m.id.substring(0, 2) == "-2"))
              return (
                <MemorialContribution
                  key={"mc" + i}
                  setMemorialContributions={setMemorialContributions}
                  memorialContributions={memorialContributions}
                  index={i}
                  edit
                />
              );
          })}
          <br />
          <button type="submit">Update</button>
        </Form>
      </EditObituarySection>
    </>
  );
};

const ObituaryList = styled.div`
  padding: 25px;
  min-height: 300px;
`;

const ObitRow = styled(Table.Row)`
  td {
    &:hover {
      background: rgb(0, 0, 0, 0.1);
      cursor: pointer;

      &.deleteCell {
        background: white !important;
        cursor: auto;
        button {
          cursor: pointer;
        }
      }
    }
  }
`;

const EditObituaryList = () => {
  const history = useHistory();
  const obitsQuery = useQuery(queries.getObituariesList);
  const [name, setName] = useState("");
  const [obits, setObits] = useState([]);
  // const obits =
  // 	obitsQuery.data &&
  // 	obitsQuery.data.obituaries.filter(o =>
  // 		o.name.toLowerCase().includes(name.toLowerCase()),
  // 	);
  //WHEN YOU come back we have to set new obits on deletion of one in handledetlete
  const [viewing, setViewing] = useState(20);
  const [deleteObit, deleteObitStatus] = useMutation(mutations.deleteObituary);

  useEffect(() => {
    if (obitsQuery.data && obitsQuery.data.obituaries) {
      setObits(obitsQuery.data.obituaries);
    }
  }, [obitsQuery.data]);

  const handleDelete = (id) => {
    if (
      confirm(
        "Are you sure you want to delete this obituary? This cannot be undone."
      )
    ) {
      deleteObit({
        variables: {
          input: id,
        },
      }).then((data) => {
        setObits(obits.filter((o) => o.id != data.data.deleteObituary.id));
      });
    }
  };

  return (
    <>
      <Dimmer active={obitsQuery.loading} />
      <Loader active={obitsQuery.loading} />
      <ObituaryList>
        <Input
          label="Search for Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <h2>Click on a cell to edit</h2>
        {obits && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                <Table.HeaderCell width={4}>Death Date</Table.HeaderCell>
                <Table.HeaderCell width={1}>Delete</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {obits
                .filter((o) =>
                  o.name.toLowerCase().includes(name.toLowerCase())
                )
                .map((o) => (
                  <ObitRow key={o.id}>
                    <Table.Cell
                      onClick={() =>
                        history.push("/admin/edit_obituary/" + o.id)
                      }
                    >
                      {o.name}
                    </Table.Cell>
                    <Table.Cell
                      onClick={() =>
                        history.push("/admin/edit_obituary/" + o.id)
                      }
                    >
                      {moment
                        .tz(parseInt(o.deathDate), "America/Chicago")
                        .format("MMM DD, YYYY")}
                    </Table.Cell>
                    <Table.Cell
                      onClick={() => handleDelete(o.id)}
                      className="deleteCell"
                    >
                      <button>delete</button>
                    </Table.Cell>
                  </ObitRow>
                ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Menu floated="right" pagination>
                    <Menu.Item as="a">See more</Menu.Item>
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </ObituaryList>
    </>
  );
};

const EditObituary = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/admin/edit_obituary/:id"
          component={EditSingleObituary}
        />
        <Route component={EditObituaryList} />
      </Switch>
    </>
  );
};

export default EditObituary;
