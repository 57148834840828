import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

body {
	margin: 0;
	font-family: 'DM Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;

}

.pushable{
	/* scroll-behavior: smooth; */
}

#root{
	height: 100%;
}

.App{
	height: 100%;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

h1 {
	font-family: 'DM Serif Text';
	font-style: normal;
	font-weight: normal;
	font-size: 32px;
	line-height: 44px;
	align-items: center;
	letter-spacing: 0.03em;
}

h2 {
	font-family: 'DM Serif Text';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 33px;
	align-items: center;
	letter-spacing: 0.04em;
	margin-bottom: 0;
}

h3 {
	margin-top: 0px;
	font-family: 'DM Serif Text';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 27px;
	align-items: center;
	letter-spacing: 0.05em;
}

.subtitle {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	align-items: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

/* button {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	align-items: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
} */

.big-body {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	align-items: center;
	letter-spacing: 0.03em;
}

.small-body {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	align-items: center;
	letter-spacing: 0.03em;
}

.caption {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	align-items: center;
	letter-spacing: 0.05em;
}

.placeholder {
	font-family: 'DM Sans';
	font-style: italic;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	align-items: center;
	letter-spacing: 0.05em;
}

ul{
	padding-right: 10px;
}

.mb-16 {
	margin-bottom: 16px;
}


`;
