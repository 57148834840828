import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import queries from '../../helpers/queries';
import { Dimmer, Loader, Table, Checkbox } from 'semantic-ui-react';
import mutations from '../../helpers/mutations';

const Container = styled.div`
	padding: 25px;
	min-height: 300px;
`;

const ViewPosts = () => {
	const postsQuery = useQuery(queries.getPosts);
	const [includeDenied, setIncludeDenied] = useState(false);
	const [includeApproved, setIncludeApproved] = useState(false);
	const toggleIncludeDenied = () => {
		setIncludeDenied(!includeDenied);
	};
	const toggleIncludeApproved = () => setIncludeApproved(!includeApproved);
	const posts =
		postsQuery.data &&
		postsQuery.data.posts.filter(
			p =>
				(!p.approved || includeApproved) &&
				(!p.denied || includeDenied),
		);
	const [approvePost, approvePostStatus] = useMutation(mutations.approvePost);
	const [denyPost, denyPostStatus] = useMutation(mutations.denyPost);
	const approve = id => {
		approvePost({
			variables: {
				input: id,
			},
		});
	};
	const deny = id => {
		denyPost({
			variables: {
				input: id,
			},
		});
	};
	return (
		<>
			<Dimmer active={postsQuery.loading} />
			<Loader active={postsQuery.loading} />
			<Container>
				<Checkbox
					label='Include Denied Posts'
					checked={includeDenied}
					onChange={toggleIncludeDenied}
				/>
				<br />
				<Checkbox
					label='Include Approved Posts'
					checked={includeApproved}
					onChange={toggleIncludeApproved}
				/>
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={1}>Email</Table.HeaderCell>
							<Table.HeaderCell width={3}>
								Obituary
							</Table.HeaderCell>
							<Table.HeaderCell width={4}>
								Message
							</Table.HeaderCell>
							<Table.HeaderCell width={1}>
								Approve
							</Table.HeaderCell>
							<Table.HeaderCell width={1}>Deny</Table.HeaderCell>
						</Table.Row>
						{posts &&
							posts.map(p => {
								return (
									<Table.Row key={p.id}>
										<Table.Cell>{p.email}</Table.Cell>
										<Table.Cell>
											{p.guestBook.obituary.name}
										</Table.Cell>
										<Table.Cell>{p.text}</Table.Cell>
										<Table.Cell>
											{!p.approved && (
												<button
													onClick={() =>
														approve(p.id)
													}
												>
													Approve
												</button>
											)}
											{p.approved && 'Currently Approved'}
										</Table.Cell>
										<Table.Cell>
											{!p.denied && (
												<button
													onClick={() => deny(p.id)}
												>
													Deny
												</button>
											)}
											{p.denied && 'Currently Denied'}
										</Table.Cell>
									</Table.Row>
								);
							})}
					</Table.Header>
					<Table.Body></Table.Body>
				</Table>
			</Container>
		</>
	);
};

export default ViewPosts;
