import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Input, Dimmer, Loader, Form } from "semantic-ui-react";
import { StandardButton, fonts, colors } from "../../styles/reusableStyles";
import { useLazyQuery, useMutation } from "@apollo/client";
import queries from "../../helpers/queries";
import mutations from "../../helpers/mutations";
import { Switch, Route, useHistory, useLocation } from "react-router";
import EditObituary from "./EditObituary";
import ViewPosts from "./ViewPosts";
import { useDropzone } from "react-dropzone";
import audioLogo from "../../media/audiofile.png";
import axios from "axios";

const AdminContainer = styled.div`
  padding: 20px;
`;

const PWInput = styled(Input)`
  margin-bottom: 16px;
`;

const Error = styled.div`
  color: red;
  ${fonts.bigBody}
`;

const ViewToggle = styled.div`
  display: flex;
  max-width: 300px;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const ToggleButton = styled.button`
  height: 40px;
  flex: 1;
  margin: 5px;
  cursor: pointer;
  &.active {
    border-bottom: 2px solid ${colors.primaryGreen};
    font-weight: bold;
  }
  &:focus {
    outline: 0;
  }
`;
const AddObituary = styled.div`
  width: 80%;
  max-width: 772px;
  margin: auto;
  margin-bottom: 40px;
  h2 {
    margin-bottom: 24px;
  }
  .big-height {
    textarea {
      height: 300px;
    }
  }
`;

export const AddShiva = styled.a`
  cursor: pointer;
  display: block;
  margin-bottom: 24px;
`;

const ShivaTitle = styled.h3`
  a {
    cursor: pointer;
    font-size: 14px;
  }
`;

const LogoContainer = styled.img`
  max-width: 300px;
  margin-bottom: 16px;
`;

const LogoDrop = styled.div`
  cursor: pointer;
  width: 200px;
  height: 200px;
  padding: 10px;
  border: 1px solid ${colors.black};
  outline: none !important;
  margin-bottom: 16px;
`;

export const ChangeImage = styled.label`
  &:hover {
    cursor: pointer;
  }
  color: blue;
`;

export const uploadfileAndGetUrl = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  const { data: imageUrl } = await axios.post(
    `${process.env.REACT_APP_SERVER_ENDPOINT}/file`,
    formData
  );
  return imageUrl;
};

export const MemorialContribution = ({
  setMemorialContributions,
  memorialContributions,
  index,
  edit,
}) => {
  const handleDelete = () => {
    if (edit && memorialContributions[index].id != -1) {
      setMemorialContributions(
        memorialContributions.map((m, i) => {
          if (index == i) {
            return { ...m, id: "-2" + m.id };
          }
          return m;
        })
      );
    } else {
      setMemorialContributions(
        memorialContributions.filter((m, i) => i != index)
      );
    }
  };
  const handleChange = (type, value) => {
    setMemorialContributions(
      memorialContributions.map((m, i) => {
        if (i == index) {
          return { ...m, [type]: value };
        }
        return m;
      })
    );
  };
  return (
    <>
      <ShivaTitle>
        Memorial Contribution {index + 1} <a onClick={handleDelete}>Delete</a>
      </ShivaTitle>
      <Form.Input
        // key={'mcname'}
        required
        label="Name"
        value={memorialContributions[index].name}
        onChange={(e) => {
          e.preventDefault();
          handleChange("name", e.target.value);
        }}
      />
      <Form.TextArea
        label="Address"
        value={memorialContributions[index].address || ""}
        onChange={(e) => {
          e.preventDefault();
          handleChange("address", e.target.value);
        }}
      />
      <Form.Input
        label="Website"
        value={memorialContributions[index].website || ""}
        onChange={(e) => {
          e.preventDefault();
          handleChange("website", e.target.value);
        }}
      />
    </>
  );
};

export const Shiva = ({ setShivas, shivas, index, edit }) => {
  const handleDelete = () => {
    if (edit) {
      if (shivas[index].id == -1)
        setShivas(shivas.filter((s, i) => i != index));
      else {
        setShivas(
          shivas.map((s, i) => {
            if (index == i) {
              return { ...s, id: "-2" + s.id };
            }
            return s;
          })
        );
      }
    } else {
      setShivas(shivas.filter((s, i) => i != index));
    }
  };
  const handleChange = (type, value) => {
    setShivas(
      shivas.map((s, i) => {
        if (i == index) {
          return { ...s, [type]: value };
        }
        return s;
      })
    );
  };
  return (
    <>
      <ShivaTitle>
        Shiva {edit ? "" : index + 1} <a onClick={handleDelete}>Delete</a>
      </ShivaTitle>
      <Form.TextArea
        label="Location Name"
        value={shivas[index].locationName}
        onChange={(e) => {
          e.preventDefault();
          handleChange("locationName", e.target.value);
        }}
      />
      <Form.TextArea
        label="Address"
        value={shivas[index].address}
        onChange={(e) => {
          e.preventDefault();
          handleChange("address", e.target.value);
        }}
      />
      <Form.Input
        type="date"
        label="Date"
        value={shivas[index].date}
        onChange={(e) => {
          e.preventDefault();
          handleChange("date", e.target.value);
        }}
      />
      <Form.Input
        type="time"
        label="Start Time (CST)"
        value={shivas[index].startTime}
        onChange={(e) => {
          e.preventDefault();
          handleChange("startTime", e.target.value);
        }}
      />
      <Form.Input
        type="time"
        label="End Time (CST)"
        value={shivas[index].endTime}
        onChange={(e) => {
          e.preventDefault();
          handleChange("endTime", e.target.value);
        }}
      />
      <Form.Input
        label="Zoom Link"
        value={shivas[index].link}
        onChange={(e) => {
          e.preventDefault();
          handleChange("link", e.target.value);
        }}
      />
    </>
  );
};

export const Dropzone = ({ path, setImage, setPreview, audio }) => {
  const onDrop = useCallback(
    ([file]) => {
      setImage(file);
      setPreview(!audio ? URL.createObjectURL(file) : "");
    },
    [audio, setImage, setPreview]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  if (path)
    return (
      <>
        <LogoContainer src={path} />
      </>
    );
  return (
    <LogoDrop {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop your {!audio ? "image" : "audio file"} here ...</p>
      ) : (
        <p>
          Click Here to select your {!audio ? "image" : "audio file"}, or drag
          it into the box
        </p>
      )}
    </LogoDrop>
  );
};

const Admin = () => {
  const [view, setView] = useState(0);
  const [password, setPassword] = useState("");
  const [shivas, setShivas] = useState([]);
  const [memorialContributions, setMemorialContributions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [deathDate, setDeathDate] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [description, setDescription] = useState("");
  const [cemeteryName, setCemeteryName] = useState("");
  const [cemeteryAddress, setCemeteryAddress] = useState("");
  const [streamingLink, setStreamingLink] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [useMenorahImage, setUseMenorahImage] = useState(false);

  const [audioFile, setAudioFile] = useState("");
  const [audioFilePreview, setAudioFilePreview] = useState("");

  const [serviceType, setServiceType] = useState("");
  const [serviceAddress, setServiceAddress] = useState("");
  const [serviceDate, setServiceDate] = useState("");
  const [serviceTime, setServiceTime] = useState("");
  const [privateService, setPrivateService] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [checkPassword, { data, error, loading }] = useLazyQuery(
    queries.checkPassword
  );
  useEffect(() => {
    if (data && data.checkPassword) {
      setView(1);
      if (location.pathname == "/admin" || location.pathname == "/admin/")
        history.push("/admin/add_obituary");
    } else if (data && !data.checkPassword) {
      setPwError("Incorrect Password");
    }
  }, [data, history, location.pathname]);
  const [pwError, setPwError] = useState("");
  const handlePWChange = (pw) => {
    setPassword(pw);
    setPwError("");
  };
  const addShiva = (e) => {
    e.preventDefault();
    setShivas([
      ...shivas,
      {
        id: "-1",
        locationName: "",
        address: "",
        date: "",
        startTime: "",
        endTime: "",
        link: "",
      },
    ]);
  };

  const getSortValue = () => {
    if (memorialContributions.length)
      return memorialContributions[memorialContributions.length - 1].sort + 1;
    return 1;
  };

  const addMemorialContribution = (e) => {
    e.preventDefault();
    setMemorialContributions([
      ...memorialContributions,
      {
        id: "-1",
        name: "",
        address: "",
        website: "",
        sort: getSortValue(),
      },
    ]);
  };

  const resetFields = () => {
    setShivas([]);
    setMemorialContributions([]);
    setVisible(false);
    setName("");
    setBirthDate("");
    setDeathDate("");
    setExcerpt("");
    setDescription("");
    setCemeteryAddress("");
    setCemeteryName("");
    setStreamingLink("");
    setAudioFile(null);
    setImage(null);
    setPreview("");
    setUseMenorahImage(false);
    setServiceAddress("");
    setServiceDate("");
    setServiceTime("");
    setPrivateService(false);
    setServiceType("");
    setSendEmail(false);
  };

  const [
    addObituary,
    { loading: createObitLoading, data: createObitData, error: createObitErr },
  ] = useMutation(mutations.createObituary);

  const changeImage = () => {
    setPreview("");
    setImage(null);
  };

  const changeAudioFile = () => {
    setAudioFile(null);
    setAudioFilePreview("");
  };

  const handleSubmit = async () => {
    if ((serviceDate || serviceTime) && !(serviceDate && serviceTime)) {
      alert("If you enter a service date, you must also enter a service time");
      return;
    }

    let imageUrl;
    let audioFileUrl;

    if (image) {
      imageUrl = await uploadfileAndGetUrl(image);
    }

    if (audioFile) {
      audioFileUrl = await uploadfileAndGetUrl(audioFile);
    }

    addObituary({
      variables: {
        input: {
          name,
          birthDate,
          deathDate,
          excerpt,
          description,
          cemeteryName,
          cemeteryAddress,
          streamingLink,
          image: imageUrl,
          audioFile: audioFileUrl,
          useMenorahImage,
          visible,
          sendEmail,
          service: {
            type: serviceType,
            address: serviceAddress,
            date: serviceDate,
            time: serviceTime,
            isPrivate: privateService,
          },
          shivas: shivas.map((s, i) => ({ ...s, index: i })),
          memorialContributions: memorialContributions,
        },
      },
    })
      .then((data) => {
        alert("successfully added obituary");
        resetFields();
        history.push("/admin/edit_obituary/" + data.data.createObituary.id);
      })
      .catch((err) => {
        console.log(err);
        alert(
          "There was an error adding your obituary, please contact the site administrator"
        );
      });
  };

  return (
    <>
      <Dimmer active={loading || createObitLoading}>
        <Loader active={loading || createObitLoading}>Loading</Loader>
      </Dimmer>
      <AdminContainer>
        {view == 0 && (
          <>
            <PWInput
              label="password"
              type="password"
              onChange={(data, { value }) => handlePWChange(value)}
              error={pwError ? true : false}
            />
            {pwError && (
              <>
                <br />
                <Error>Incorrect Password</Error>
              </>
            )}
            <br />
            <StandardButton
              onClick={() => checkPassword({ variables: { password } })}
            >
              submit
            </StandardButton>
          </>
        )}
        {view == 1 && (
          <>
            <ViewToggle>
              <ToggleButton
                onClick={() => history.push("/admin/add_obituary")}
                className={
                  location.pathname.includes("add_obituary") ? "active" : ""
                }
              >
                Add Obituary
              </ToggleButton>
              <ToggleButton
                onClick={() => history.push("/admin/edit_obituary")}
                className={
                  location.pathname.includes("edit_obituary") ? "active" : ""
                }
              >
                Edit Obituary
              </ToggleButton>
              <ToggleButton
                onClick={() => history.push("/admin/view_posts")}
                className={
                  location.pathname.includes("view_posts") ? "active" : ""
                }
              >
                View/Approve Posts
              </ToggleButton>
            </ViewToggle>
          </>
        )}
      </AdminContainer>
      {view == 1 && (
        <Switch>
          <Route
            path="/admin/add_obituary"
            render={() => (
              <AddObituary>
                <Form onSubmit={handleSubmit}>
                  <h2>Basic Obituary Info</h2>
                  <Form.Checkbox
                    label="Publish this obituary"
                    checked={visible}
                    onChange={() => setVisible(!visible)}
                  />
                  <Form.Checkbox
                    label="Send Email Alert (will only send email if an email has not yet been sent for this obituary)"
                    checked={sendEmail}
                    onChange={() => setSendEmail(!sendEmail)}
                  />
                  <Form.Input
                    required
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Form.Input
                    type="date"
                    label="Birth Date"
                    placeholder="mm/dd/yyyy"
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                  />
                  <Form.Input
                    required
                    type="date"
                    label="Death Date"
                    placeholder="mm/dd/yyyy"
                    value={deathDate}
                    onChange={(e) => setDeathDate(e.target.value)}
                  />
                  <Form.Input
                    required
                    label="Excerpt"
                    value={excerpt}
                    onChange={(e) => setExcerpt(e.target.value)}
                  />
                  <Form.TextArea
                    required
                    className="big-height"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <Form.Input
                    label="Cemetery Name"
                    value={cemeteryName}
                    onChange={(e) => setCemeteryName(e.target.value)}
                  />
                  <Form.TextArea
                    label="Cemetery Address"
                    value={cemeteryAddress}
                    onChange={(e) => setCemeteryAddress(e.target.value)}
                  />
                  <Form.Input
                    label="Streaming Link"
                    value={streamingLink}
                    onChange={(e) => setStreamingLink(e.target.value)}
                  />
                  <label>Audio Link (mp3)</label>
                  <br />
                  {audioFile && (
                    <>
                      <ChangeImage onClick={changeAudioFile}>
                        Change Audio File
                      </ChangeImage>
                      <br />
                    </>
                  )}
                  <Dropzone
                    audio
                    setImage={setAudioFile}
                    setPreview={setAudioFilePreview}
                    path={audioFile ? audioLogo : null}
                  />
                  <br />
                  <label>Image (optional)</label>
                  <br />
                  {preview && (
                    <>
                      <ChangeImage onClick={changeImage}>
                        Change Image
                      </ChangeImage>
                      <br />
                    </>
                  )}
                  <Dropzone
                    path={preview}
                    setImage={setImage}
                    setPreview={setPreview}
                  />
                  <Form.Checkbox
                    label="Use Menorah image if no image provided (otherwise this will show one of the default images)"
                    checked={useMenorahImage}
                    onChange={() => setUseMenorahImage(!useMenorahImage)}
                  />
                  <h2>Service Information</h2>
                  <Form.TextArea
                    required
                    label="Service Type"
                    value={serviceType}
                    onChange={(e) => setServiceType(e.target.value)}
                  />
                  <Form.TextArea
                    label="Service Address"
                    value={serviceAddress}
                    onChange={(e) => setServiceAddress(e.target.value)}
                  />
                  <Form.Input
                    type="date"
                    label="Service Date"
                    value={serviceDate}
                    onChange={(e) => setServiceDate(e.target.value)}
                  />
                  <Form.Input
                    type="time"
                    label="Service Time (Central Time)"
                    value={serviceTime}
                    onChange={(e) => setServiceTime(e.target.value)}
                  />
                  <Form.Checkbox
                    label="Private Service"
                    checked={privateService}
                    onChange={() => setPrivateService(!privateService)}
                  />
                  <h2>Shiva Information</h2>
                  <AddShiva onClick={addShiva}>Add Shiva</AddShiva>
                  {shivas.map((s, i) => (
                    <Shiva
                      key={i}
                      shivas={shivas}
                      setShivas={setShivas}
                      index={i}
                    />
                  ))}
                  <h2>Memorial Contributions</h2>
                  <AddShiva onClick={addMemorialContribution}>
                    Add Memorial Contribution
                  </AddShiva>
                  {memorialContributions.map((m, i) => (
                    <MemorialContribution
                      key={"mc" + i}
                      setMemorialContributions={setMemorialContributions}
                      memorialContributions={memorialContributions}
                      index={i}
                    />
                  ))}
                  <br />
                  <button type="submit">Submit</button>
                </Form>
              </AddObituary>
            )}
          />
          <Route path="/admin/edit_obituary" component={EditObituary} />
          <Route path="/admin/view_posts" component={ViewPosts} />
        </Switch>
      )}
    </>
  );
};

export default Admin;
