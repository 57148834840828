import React from 'react';
import styled, { css } from 'styled-components';
import { colors, fonts } from '../styles/reusableStyles';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const MobileMenuContainer = styled.div`
	height: 100%;
	width: 240px;
	background-color: ${colors.navPeach};
	padding-left: 24px;
	padding-top: 12px;
	padding-bottom: 20px;
	overflow-y: scroll;
	overflow-x: hidden;
`;

const NavLinkCSS = css`
	height: 40px;
	line-height: 40px;
	color: #636363;

	&:hover {
		color: #636363;
	}
`;

const NavPrimaryLink = styled(NavLink)`
	${fonts.h3}
	${NavLinkCSS}
`;

const NavSecondaryLink = styled(NavLink)`
	${fonts.smallBody}
	${NavLinkCSS}
    padding-left: 16px;
`;

const NavSecondaryHashLink = styled(HashLink)`
	${fonts.smallBody}
	${NavLinkCSS}
    padding-left: 16px;
`;

const NavAlertLink = styled(NavPrimaryLink)`
	color: ${colors.primaryOrange};
`;

const NavGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
`;

const MobileMenu = ({ toggleMenu }) => {
	return (
		<MobileMenuContainer>
			<NavGroup>
				<NavPrimaryLink onClick={toggleMenu} to='/'>
					Home
				</NavPrimaryLink>
			</NavGroup>
			<NavGroup>
				<NavPrimaryLink onClick={toggleMenu} to='/services'>
					Services
				</NavPrimaryLink>
				<NavSecondaryLink onClick={toggleMenu} to='/services/options'>
					Service Options
				</NavSecondaryLink>
				<NavSecondaryLink
					onClick={toggleMenu}
					to='/services/preplanning'
				>
					Preplanning
				</NavSecondaryLink>
				<NavSecondaryLink onClick={toggleMenu} to='/services/monuments'>
					Monuments
				</NavSecondaryLink>
				<NavSecondaryLink
					onClick={toggleMenu}
					to='/services/grief_support'
				>
					Grief Support
				</NavSecondaryLink>
			</NavGroup>
			<NavGroup>
				<NavPrimaryLink onClick={toggleMenu} to='/obituaries'>
					Obituaries
				</NavPrimaryLink>
			</NavGroup>
			<NavGroup>
				<NavPrimaryLink onClick={toggleMenu} to='/about'>
					About
				</NavPrimaryLink>
				<NavSecondaryLink onClick={toggleMenu} to='/about/staff'>
					Our Staff
				</NavSecondaryLink>
				<NavSecondaryLink onClick={toggleMenu} to='/about/facility'>
					Our Facility
				</NavSecondaryLink>
			</NavGroup>
			<NavGroup>
				<NavPrimaryLink onClick={toggleMenu} to='/contact'>
					Contact Us
				</NavPrimaryLink>
				<NavSecondaryHashLink onClick={toggleMenu} to='/contact#FAQ'>
					FAQ
				</NavSecondaryHashLink>
			</NavGroup>
			<NavGroup>
				<NavAlertLink onClick={toggleMenu} to='/immediate_need'>
					Immediate Need
				</NavAlertLink>
			</NavGroup>
		</MobileMenuContainer>
	);
};

export default MobileMenu;
