import styled, { css } from 'styled-components';
export const colors = {
	primaryOrange: '#BB4705',
	primaryGreen: '#516A4B',
	secondaryOrange: '#BF6E40',
	navPeach: '#FBE7D0',
	offWhite: '#FFFAF4',
	greyText: '#636663',
	smallGreyText: '#494D49',
};

export const sizes = {
	tablet: '600px',
	navMin: '979px',
	max: '1024px',
};
export const fonts = {
	h1: css`
		font-family: 'DM Serif Text';
		font-style: normal;
		font-weight: normal;
		font-size: 32px;
		line-height: 44px;
		align-items: center;
		letter-spacing: 0.03em;
	`,
	h2: css`
		font-family: 'DM Serif Text';
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 33px;
		align-items: center;
		letter-spacing: 0.04em;
	`,

	h3: css`
		font-family: 'DM Serif Text';
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 27px;
		align-items: center;
		letter-spacing: 0.05em;
	`,

	subtitle: css`
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		align-items: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	`,

	desktopSubtitle: css`
		font-family: DM Sans;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 26px;
		display: flex;
		align-items: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #bf6e40;
	`,

	button: css`
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		align-items: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	`,

	bigBody: css`
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 21px;
		align-items: center;
		letter-spacing: 0.03em;
	`,

	smallBody: css`
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		align-items: center;
		letter-spacing: 0.03em;
	`,

	caption: css`
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		align-items: center;
		letter-spacing: 0.05em;
	`,

	desktopCaption: css`
		font-family: DM Sans;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		display: flex;
		align-items: center;
		letter-spacing: 0.05em;
		color: ${colors.smallGreyText};
	`,

	quote: css`
		font-family: 'DM Serif Text';
		font-style: italic;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.05em;
	`,

	placeholder: css`
		font-family: 'DM Sans';
		font-style: italic;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		align-items: center;
		letter-spacing: 0.05em;
	`,
};

export const StandardButton = styled.button`
	background: ${colors.secondaryOrange};
	border: 2px solid ${colors.secondaryOrange};
	border-radius: 48px;
	height: 48px;
	padding: 8px 16px;
	${fonts.button}
	color: white;
	outline: none;
	cursor: pointer;
`;

export const FormSubmitButton = styled(StandardButton)`
	display: block;
	margin: auto;
	background: ${colors.primaryOrange};
	border-color: ${colors.primaryOrange};
	min-width: 150px;
`;

export const Banner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 200px;
	background: ${({ img }) => `url(${img})`};
	background-size: cover;
	margin-bottom: 24px;

	@media screen and (min-width: ${sizes.tablet}) {
		height: 320px;
		margin-bottom: 40px;
	}
`;

export const HeroDesc = styled.div`
	${fonts.bigBody}
	padding: 0 16px;
	text-align: center;
	color: ${colors.smallGreyText};
	margin-bottom: 24px;

	@media screen and (min-width: ${sizes.tablet}) {
		max-width: 772px;
		margin: auto;
		margin-bottom: 32px;
	}
`;

export const DownloadButton = styled(StandardButton)`
	display: block;
	margin: auto;
	margin-bottom: 16px;
	background-color: ${colors.primaryGreen};
	border-color: ${colors.primaryGreen};
	line-height: 28px;
	width: 200px;
	&:hover {
		color: white;
	}
`;

export const GreenButton = styled(StandardButton)`
	display: block;
	margin: auto;
	background-color: ${colors.primaryGreen};
	border-color: ${colors.primaryGreen};
`;

export const CTACard = styled.div`
	background: white;
	box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.1);
	border-radius: 12px;
	padding: 24px 16px;
	margin: 0 24px;
	margin-bottom: 40px;
	text-align: center;

	h2 {
		color: ${colors.primaryGreen};
		margin-bottom: 7px;
    }
    
    div{
        margin-bottom : 16px;
        ${fonts.bigBody}
        color: ${colors.smallGreyText};
	}
	
	@media screen and (min-width: ${sizes.tablet}){
		width: 80%;
		max-width: 772px;
		margin: 0 auto 40px auto;
	}
`;

export const ModalContainer = styled.div`
	padding: 32px;
	border-radius: 12px;
	background-color: ${colors.offWhite};
	box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);
`;

export const ModalTitle = styled.h3`
	color: ${colors.primaryOrange};
	text-align: center;
	margin-bottom: 16px;
`;

export const ModalBody = styled.div`
	${fonts.smallBody}
	color: ${colors.smallGreyText};
`;
