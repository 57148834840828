import React, { useState } from "react";
import {
  Banner,
  colors,
  fonts,
  StandardButton,
  sizes,
} from "../styles/reusableStyles";
import greenLeaves from "../media/greenLeaves.png";
import styled from "styled-components";
import map from "../media/Map.png";
import phoneIcon from "../media/Phone.png";
import mail from "../media/Mail.png";
import { Form } from "semantic-ui-react";
import FAQs from "../helpers/FAQs";
import up from "../media/Up.svg";
import down from "../media/Down.png";
import { useMutation } from "@apollo/client";
import mutations from "../helpers/mutations";

const Heading = styled.h1`
  color: white;
`;

const Content = styled.div`
  text-align: center;
  margin: 0 16px;
  h2 {
    color: ${colors.primaryGreen};
    margin-bottom: 16px;
    span {
      white-space: nowrap;
    }
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  width: fit-content;
  margin-bottom: 16px;
`;

const Icon = styled.img`
  height: ${({ mail }) => (mail ? "16px" : "21px")};
`;
const Text = styled.span`
  ${fonts.smallBody}
  @media screen and (min-width: ${sizes.tablet}) {
    ${fonts.bigBody}
    max-width: 280px;
  }
  padding-left: 10px;
  max-width: 200px;
  text-align: center;
  color: ${colors.smallGreyText};
`;

const TextLink = styled.a`
  ${fonts.smallBody}
  @media screen and (min-width: ${sizes.tablet}) {
    ${fonts.bigBody}
    max-width: 280px;
  }
  padding-left: 10px;
  max-width: 200px;
  text-align: center;
  color: ${colors.smallGreyText};
  text-decoration: underline;
  &:hover {
    color: ${colors.smallGreyText};
    text-decoration: none;
  }
`;
const Section = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: ${sizes.tablet}) {
    width: 80%;
    margin: 0 auto 40px auto;
    max-width: 772px;
  }
`;

const HelpCard = styled.div`
  padding: 16px;
  background: ${colors.navPeach};
  box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);
  border-radius: 12px;
  margin-bottom: 32px;
  h2 {
    color: ${colors.smallGreyText};
    margin-bottom: 16px;
  }
  @media screen and (min-width: ${sizes.tablet}) {
    width: 80%;
    max-width: 772px;
    margin: 0 auto 48px auto;
    padding: 24px 36px;
  }
`;
const Subtitle = styled.div`
  ${fonts.smallBody};
  color: ${colors.primaryOrange};
  margin-bottom: 8px;
  a {
    color: ${colors.primaryOrange};

    text-decoration: underline;
    font-weight: bold;
    &:hover {
      color: ${colors.primaryOrange};
    }
  }
`;
const HelpForm = styled(Form)`
  text-align: left;
`;
const FormSubmitButton = styled(StandardButton)`
  display: block;
  margin: auto;
  background: ${colors.primaryOrange};
  border-color: ${colors.primaryOrange};
  min-width: 150px;
`;

const FAQSection = styled.div``;
const FAQContainer = styled.div`
  text-align: left;
  padding: 12px 8px;
  border-bottom: 1px solid #516a4b;
  cursor: pointer;
`;

const QuestionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .question {
    ${fonts.bigBody}
    font-weight: bold;
    color: ${colors.smallGreyText};
    &.active {
      color: ${colors.secondaryOrange};
    }
  }
  .icon {
    height: 24px;
    width: 24px;
    margin-left: 10px;

    &.active {
      fill: ${colors.secondaryOrange};
    }
  }
`;

const Answer = styled.div`
  margin-top: 4px;
  ${fonts.bigBody}
  color: ${colors.smallGreyText};
`;

const SubmissionConfirmation = styled.div`
  color: ${colors.smallGreyText};
`;

const FAQ = ({ faq }) => {
  const [active, setActive] = useState(false);
  return (
    <FAQContainer>
      <QuestionRow onClick={() => setActive(!active)}>
        <div className={`question${active ? " active" : ""}`}>
          Q: {faq.question}
        </div>
        <img
          className={`icon${active ? " active" : ""}`}
          src={active ? up : down}
        />
      </QuestionRow>
      {active && <Answer>{faq.answer}</Answer>}
    </FAQContainer>
  );
};

const ContactUs = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [requestsPricing, setRequestsPricing] = useState(false);
  const [submitContact, { loading, err, data }] = useMutation(
    mutations.contactUs
  );
  const handleSubmit = () => {
    submitContact({
      variables: {
        name,
        phone,
        email,
        subject,
        message,
        requestsPricing,
      },
    });
  };

  return (
    <>
      <Banner img={greenLeaves}>
        <Heading>Contact Us</Heading>
      </Banner>
      <Content>
        <h2>
          Goodman-Bensman <span>Whitefish Bay Funeral Home</span>
        </h2>
        <Section>
          <Info>
            <Icon src={map} />
            <TextLink
              href="https://www.google.com/maps/place/Goodman-Bensman+Funeral+Home/@43.1032793,-87.9088901,17z/data=!3m1!4b1!4m5!3m4!1s0x88051eeedc08e135:0xa81e5c49edd18ec7!8m2!3d43.1032754!4d-87.9067014"
              target="_blank"
            >
              4750 N. Santa Monica Blvd. <br />
              Whitefish Bay, WI 53211
            </TextLink>
          </Info>
          <Info>
            <Icon src={phoneIcon} />
            <TextLink href="tel:(414) 964-3111">(414) 964-3111</TextLink>
          </Info>
          <Info>
            <Text>FAX (414) 964-3535</Text>
          </Info>
          <Info>
            <Icon mail src={mail} />
            <TextLink href="mailto:info@goodmanbensman.com">
              info@goodmanbensman.com
            </TextLink>
          </Info>
        </Section>
        <HelpCard>
          <h2>How can we help?</h2>
          <Subtitle>
            If a death has occurred, please call us immediately at{" "}
            <a href="tel:(414) 964-3111">(414) 964-3111</a>
          </Subtitle>
          {!(data && data.contactUs) && (
            <HelpForm onSubmit={handleSubmit}>
              <Form.Input
                required
                label="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Input
                required
                type="email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Input
                required
                label="Contact Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <Form.Input
                required
                label="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <Form.TextArea
                required
                label="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Form.Checkbox
                label="Check if you would like to receive pricing information"
                checked={requestsPricing}
                onChange={() => setRequestsPricing(!requestsPricing)}
              />
              <FormSubmitButton type="submit">Submit</FormSubmitButton>
            </HelpForm>
          )}
          {data && data.contactUs && (
            <SubmissionConfirmation>
              Thank you for your message. We will reach out to you shortly.
            </SubmissionConfirmation>
          )}
        </HelpCard>
        <Section id="FAQ">
          <h2>FAQ</h2>
          {FAQs.map((f, i) => (
            <FAQ key={i} faq={f} />
          ))}
        </Section>
      </Content>
    </>
  );
};

export default ContactUs;
