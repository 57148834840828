import React from 'react';
import styled from 'styled-components';
import { Banner, colors, fonts, sizes } from '../styles/reusableStyles';
import greenBurial from '../media/greenBurial.png';
import greenBurialWisconsin from '../media/greenBurialWisconsin.png';
import HaveQuestionsCTA from './HaveQuestionsCTA';

const GreenBurialBanner = styled(Banner)`
	background: linear-gradient(
			0deg,
			rgba(73, 77, 73, 0.2),
			rgba(73, 77, 73, 0.2)
		),
		url(${greenBurial});
	background-size: cover;
	h1 {
		color: white;
	}
`;

const GreenBurialLogo = styled.img`
	width: 33%;
	max-width: 200px;
	margin-bottom: 16px;
`;

const Section = styled.div`
    text-align: center;
    margin: 0 16px;
	margin-bottom: 40px;
	@media screen and (min-width: ${sizes.tablet}){
		width: 90%;
		max-width: 772px;
		margin: 0 auto 40px auto;
	}


    h2 {
		color: ${colors.primaryGreen};
		@media screen and (min-width: ${sizes.tablet}){
			font-size: 32px;
			line-height: 44px;
		}
    }
    div{
        ${fonts.bigBody}
        color: ${colors.smallGreyText};
        text-align: left;
    }
    ul{
        color: ${colors.primaryGreen};
        text-align: left;
        ${fonts.bigBody}
        font-weight: bold;
        margin: 16px 0;
        padding-left: 16px;
    }
`;

const CemeteryOptions = styled.div`
	margin: 16px 0;
	a {
		text-align: center;
		display: block;
		color: ${colors.secondaryOrange};
		text-decoration: underline;
		&:hover {
			color: ${colors.secondaryOrange};
		}
	}
	div {
		text-align: center;
		margin-bottom: 16px;
	}
`;

const OtherResourcesList = styled.ul`
	li {
		font-weight: normal;
		color: ${colors.smallGreyText};
		margin-bottom: 16px;
		a {
			display: block;
			color: ${colors.secondaryOrange};
			text-decoration: underline;
			&:hover {
				color: ${colors.secondaryOrange};
			}
		}
	}
	@media screen and (min-width: ${sizes.tablet}) {
		text-align: center !important;
		list-style: none;
	}
`;

const GreenBurial = () => {
	return (
		<>
			<GreenBurialBanner>
				<h1>Green Burial</h1>
			</GreenBurialBanner>
			<Section>
				<GreenBurialLogo src={greenBurialWisconsin} />
				<h2>What is Green Burial?</h2>
				<div>
					Green Burial is a commitment to returning to the earth as
					naturally as possible.
					<br />
					<br />
					The most common elements of Green Burial are:
				</div>
				<ul>
					<li>no embalming</li>
					<li>biodegradable casket and shroud</li>
					<li>elimination of the outer burial container</li>
				</ul>
				<div>
					Although most Green Burial services are graveside, the
					funeral or memorial service can be personalized according to
					the wishes of loved ones, taking into consideration
					religious preference.
				</div>
			</Section>
			<Section>
				<h2>Cemetery Options</h2>
				<div>
					If you have not purchased a burial plot, there are
					cemeteries in Wisconsin that offer Green Burial:
				</div>
				<CemeteryOptions>
					<a
						href='https://greenwoodjewishcemetery.org/jewish-green-burial-milwaukee-wisconsin/'
						target='_blank'
					>
						Prairie Green Cemetery
					</a>
					<div> Milwaukee, WI</div>
					<a
						href='https://foresthomecemetery.com/green-burial'
						target='_blank'
					>
						Prairie Rest Cemetery
					</a>
					<div> Milwaukee, WI</div>
					<a
						href='https://waukesha-wi.gov/646/Prairie-Home-Cemetery'
						target='_blank'
					>
						Prairie Home Cemetery
					</a>
					<div> Waukesha, WI</div>
					<a href='http://naturalpathsanctuary.org/' target='_blank'>
						Natural Path Sanctuary
					</a>
					<div> Verona, WI</div>
				</CemeteryOptions>
			</Section>
			<Section>
				<h2>Other Resources</h2>
				<OtherResourcesList>
					<li>
						<a
							href='https://nfda.org/resources/business-technical/green-funeral-practices/what-it-means-to-be-green'
							target='_blank'
						>
							Green Funerals and Burial
						</a>
						National Funeral Directors Association
					</li>
					<li>
						<a
							href='https://www.npr.org/templates/story/story.php?storyId=17232879#:~:text=Burials%20and%20Cemeteries%20Go%20Green%20Environmentally%20friendly%20funerals%20are%20catching,natural%20return%20to%20the%20earth'
							target='_blank'
						>
							Burials and Cemeteries Go Green
						</a>
						National Public Radio
					</li>
					<li>
						<a
							href='https://funerals.org/greenburial/'
							target='_blank'
						>
							Green Burial
						</a>
						Funeral Consumers Alliance
					</li>
				</OtherResourcesList>
			</Section>
			<HaveQuestionsCTA />
		</>
	);
};

export default GreenBurial;
