import { gql } from "@apollo/client";

const mutations = {
  requestPricing: gql`
    mutation RequestPricing(
      $name: String
      $email: String!
      $phone: String!
      $cemetery: String
      $followUp: Boolean!
    ) {
      requestPricingInfo(
        input: {
          name: $name
          email: $email
          phone: $phone
          cemetery: $cemetery
          followUp: $followUp
        }
      )
    }
  `,
  contactUs: gql`
    mutation ContactUs(
      $name: String!
      $email: String!
      $phone: String!
      $subject: String!
      $message: String!
      $requestsPricing: Boolean!
    ) {
      contactUs(
        input: {
          name: $name
          email: $email
          phone: $phone
          subject: $subject
          message: $message
          requestsPricing: $requestsPricing
        }
      )
    }
  `,
  subscribe: gql`
    mutation Subscribe($name: String, $email: String!) {
      subscribe(input: { name: $name, email: $email })
    }
  `,

  createObituary: gql`
    mutation CreateObituary($input: ObituaryCreateInput!) {
      createObituary(input: $input) {
        id
      }
    }
  `,

  updateObituary: gql`
    mutation UpdateObituary($input: ObituaryCreateInput!) {
      updateObituary(input: $input) {
        id
        name
        birthDate
        deathDate
        excerpt
        description
        cemeteryName
        cemeteryAddress
        streamingLink
        imageAddress
        audioFileAddress
        useMenorahImage
        visible
        service {
          id
          type
          address
          date
          isPrivate
        }
        memorialContributions {
          id
          name
          address
          website
          sort
        }
        shivas {
          id
          start
          end
          address
          locationName
          index
        }
      }
    }
  `,

  createPost: gql`
    mutation CreatePost($input: PostCreateInput!) {
      createPost(input: $input) {
        id
      }
    }
  `,
  approvePost: gql`
    mutation ApprovePost($input: ID!) {
      approvePost(input: $input) {
        id
        approved
        denied
      }
    }
  `,
  denyPost: gql`
    mutation DenyPost($input: ID!) {
      denyPost(input: $input) {
        id
        denied
        approved
      }
    }
  `,
  deleteObituary: gql`
    mutation DeleteObituary($input: ID!) {
      deleteObituary(input: $input) {
        id
      }
    }
  `,
};

export default mutations;
