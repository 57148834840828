import React, { useState, useEffect } from 'react';
import './App.css';
import Nav from './components/Nav';
import { Switch, Route, withRouter } from 'react-router';
import Home from './components/Home';
import About from './components/About';
import styled from 'styled-components';
import ErrorPage from './components/ErrorPage';
import { Sidebar } from 'semantic-ui-react';
import MobileMenu from './components/MobileMenu';
import Footer from './components/Footer';
import Services from './components/Services';
import ServiceOptions from './components/ServiceOptions';
import Preplanning from './components/Preplanning';
import ImmediateNeed from './components/ImmediateNeed';
import ScrollToTop from './components/ScrollToTop';
import GreenBurial from './components/GreenBurial';
import ContactUs from './components/ContactUs';
import Obituaries from './components/Obituaries';
import Obituary from './components/Obituary';
import Admin from './components/Admin/Admin';
import Monuments from './components/Monuments';
import GriefSupport from './components/GriefSupport';
import { sizes } from './styles/reusableStyles';
import ReactGA from 'react-ga';
// import { Helmet } from 'react-helmet';

const NavBuffer = styled.div`
	height: 72px;
	@media screen and (min-width: ${sizes.navMin}) {
		height: 96px;
	}
`;

const PageContent = styled.div`
	background-color: #fefaf4;
`;

function App() {
	const [menuVisible, setMenuVisible] = useState(false);
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	});
	return (
		<div className='App'>
			{/* <Helmet>
				<meta
					name='description'
					content='Goodman-Bensman Whitefish Bay Funeral Home will continue to offer the highest level of compassionate care to our families while taking every precautionary step to protect the health and well-being of our community. As always, we offer phone and email arrangements so that families need not physically visit the funeral home. In accordance with state and …'
				/>
				  <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />

			</Helmet> */}
			<Nav
				menuOpen={menuVisible}
				toggleMenu={() => setMenuVisible(!menuVisible)}
			/>
			<NavBuffer />
			<Sidebar
				visible={menuVisible}
				direction={'right'}
				animation={'overlay'}
				content={
					<MobileMenu toggleMenu={() => setMenuVisible(false)} />
				}
			/>
			<Sidebar.Pushable>
				<Sidebar.Pusher
					// className={menuVisible ? 'fixed' : ''}
					dimmed={menuVisible}
					onClick={() => setMenuVisible(false)}
				>
					<PageContent>
						<ScrollToTop>
							<Switch>
								<Route exact path='/' component={Home} />
								<Route path='/about' component={About} />
								<Route
									exact
									path='/services'
									component={Services}
								/>
								<Route
									exact
									path='/services/options'
									component={ServiceOptions}
								/>
								<Route
									exact
									path='/services/preplanning'
									component={Preplanning}
								/>
								<Route
									exact
									path='/services/monuments'
									component={Monuments}
								/>
								<Route
									path='/services/grief_support'
									component={GriefSupport}
								/>
								<Route
									exact
									path='/immediate_need'
									component={ImmediateNeed}
								/>
								<Route
									exact
									path='/green_burial'
									component={GreenBurial}
								/>
								<Route path='/contact' component={ContactUs} />
								<Route
									path='/obituaries/:id'
									component={Obituary}
								/>
								<Route
									exact
									path='/obituaries'
									component={Obituaries}
								/>
								<Route path='/admin' component={Admin} />
								<Route component={ErrorPage} />
							</Switch>
						</ScrollToTop>
						<Footer />
					</PageContent>
				</Sidebar.Pusher>
			</Sidebar.Pushable>
		</div>
	);
}

export default withRouter(App);
