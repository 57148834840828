import React, { useEffect, useState } from "react";
import {
  Banner,
  HeroDesc,
  colors,
  fonts,
  StandardButton,
  DownloadButton,
  FormSubmitButton,
  sizes,
} from "../styles/reusableStyles";
import styled from "styled-components";
import preplanning from "../media/preplanning.png";
import getStarted from "../media/getStarted.png";
import { useHistory } from "react-router";
import { Form, Icon } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import mutations from "../helpers/mutations";

const whyPreplanText = [
  {
    title: "Lock in price against inflation",
    blurb:
      "In Wisconsin, if the funeral service charge and merchandise are fully funded in an irrevocable growth insurance policy, today’s prices are locked in against inflation even though they will likely increase over time. The growth of the policy is used to offset inflation. If inflation outpaces growth, the funeral home cannot charge more for the guaranteed items at the time of need. This is a smart way for prudent investors to beat inflation.",
  },
  {
    title: "Make informed decisions",
    blurb:
      "If you have ever made funeral arrangements, you recall the difficult decision-making process which occurred within a relatively short period of time and under profound emotional distress. Often, loved ones guess at what the decedent would have wanted. Preplanning affords families the time to make decisions with a clear, rational mind. It also allows time for price comparison, contemplation and conversations with those you trust. Information can be mailed or e-mailed to your home so that you can review it privately whenever and wherever you are most comfortable. Informed decisions are the best decisions.",
  },
  {
    title: '"Spend down" wisely',
    blurb:
      "“Spending down” refers to depleting assets in order to qualify for government financial aid. Usually, it arises when a loved one requires nursing home or skilled facility care. Families are often blindsided, however, when they “spend down” and fail to set aside funds for final expenses. In some cases, available assets can be deemed exempt if they are set aside in an irrevocable insurance policy before or during the “spend down” process. Don’t forfeit the financial benefits. Consult a financial planner, estate attorney and the funeral home to make the best, informed decisions for your family.",
  },
  {
    title: "Relieve emotional and financial burden on loved ones",
    blurb:
      "Perhaps the most significant benefit of preplanning is relieving the emotional and financial burden on those you love. Planning a funeral is an emotionally charged process that sometimes leads to family disagreements. Preplanning removes the guesswork and provides tremendous relief to loved ones knowing that the decisions have already been made. Additionally, if prefunded, the prearrangements also relieve family members from any financial burden. Sometimes, family members disagree about who should be financially responsible, how the cost should be divided or even how much should be spent. No one wants to impose hardship on loved ones. Imagine the peace of mind knowing that arrangements are already in place when needed.",
  },
];

const avoidMythsText = [
  {
    title: "Preplanning hastens death",
    blurb:
      "It goes without saying that this is pure superstition, but it is actually offered as an argument for avoiding preplanning. Our funeral home has generated hundreds of preneed files, some dating back decades. Those individuals are thriving. That, alone, should dispel the myth, but take it a step further. Do you really believe that funeral homes can cue lightning following a preplanning consultation?",
  },
  {
    title: "Preplanning isn’t for wealthy people",
    blurb:
      "Many families who consider themselves financially well off ignore not only the financial benefits of preplanning, but also the emotional burden of funeral planning. Funeral homes and cemeteries often require payment in advance of the funeral service. Therefore liquidity will be an issue and waiting to sell a condo or make a claim on a life insurance policy may not be an option. Second, failing to document final wishes can create long lasting conflicts among family members who disagree about financial responsibility, choice of cemetery, type of service or even selection of clergy. Those simmering disagreements boil over when combined with grief and often extend beyond the funeral. Finally, it is important to at least consider the possibility of skilled or nursing care in the years ahead and how that might affect your assets. As a smart investor, the decision to document final wishes and lock in today’s prices is a wise one even if you have the funds to pay a higher price in the future.",
  },
  {
    title: "Preplanning is expensive",
    blurb:
      "Preplanning can include prefunding, but prefunding is not required. Prefunding is simply purchasing funeral goods and services at today’s prices to be used in the future. The only cost is for the merchandise and services that you select. There are no hidden fees, and families can prefund as much or as little as they are comfortable with. There are also multi-pay options that allow you to pay for the funeral expenses over time. All funds invested in the insurance policy are returned either in a payment to the funeral home for funeral services provided or in a check to the estate. They are never kept by the insurer or the funeral home. Prearrangements can, however, be completed without any funding at all. The documentation serves as a valuable road map for loved ones who would otherwise struggle to guess about final wishes.",
  },
];

const PreplanningBanner = styled(Banner)`
  background: ${({ img }) =>
    `linear-gradient(0deg, rgba(73, 77, 73, 0.2), rgba(73, 77, 73, 0.2)), url(${img})`};
  background-size: cover;
  background-position: 0 60%;
  h1 {
    color: white;
  }
`;

const WhyPreplan = styled.div`
  margin-bottom: 24px;
  @media screen and (min-width: ${sizes.tablet}) {
    margin-bottom: 48px;
  }
  h2 {
    color: ${colors.primaryGreen};
    text-align: center;
    @media screen and (min-width: ${sizes.tablet}) {
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 16px;
    }
  }
  ul {
    @media screen and (min-width: ${sizes.tablet}) {
      max-width: 512px;
      margin: auto;
    }
  }
  li {
    ${fonts.bigBody}
    margin-bottom: 16px;
    color: ${colors.smallGreyText};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .li-blurb {
    ${fonts.bigBody}
    margin-bottom: 16px;
    color: ${colors.smallGreyText};
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;
  margin-bottom: 30px;
  @media screen and (min-width: ${sizes.tablet}) {
    margin: 0 40px 40px 40px;
  }
  text-align: center;
`;

const GetStartedCard = styled.div`
  border-radius: 36px 36px 12px 12px;

  box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);
  background: white;
  /* @media screen and (min-width: ${sizes.tablet}) {
		max-width: 772px;
	} */

  img {
    border-radius: 12px 12px 0 0;
    width: 100%;
    @media screen and (min-width: ${sizes.tablet}) {
      max-width: 772px;
      max-height: 280px;
      object-fit: cover;
    }
  }
  span {
    font-weight: bold;
  }
`;

const GetStartedContent = styled.div`
  padding: 16px;
  ${fonts.bigBody}
  color: ${colors.greyText};
  @media screen and (min-width: ${sizes.tablet}) {
    max-width: 772px;
  }

  h2 {
    color: ${colors.primaryGreen};
    margin-bottom: 24px;
    @media screen and (min-width: ${sizes.tablet}) {
      font-size: 32px;
      line-height: 44px;
    }
  }
`;

const Subtitle = styled.div`
  ${fonts.subtitle}
  color: ${colors.secondaryOrange};
  margin-bottom: 8px;
`;

const ContactUs = styled(StandardButton)`
  display: block;
  margin: auto;
  margin-top: 16px;
  background-color: ${colors.primaryOrange};
  border-color: ${colors.primaryOrange};
`;

const NotReadySection = styled.div`
  text-align: center;
  margin: 0 24px 24px 24px;
  @media screen and (min-width: ${sizes.tablet}) {
    width: 80%;
    max-width: 772px;
    margin: 0 auto 48px auto;
  }
  h2 {
    color: ${colors.primaryGreen};
    line-height: 40px;
    margin-bottom: 8px;
  }
  div {
    color: ${colors.smallGreyText};
    ${fonts.bigBody}
    margin-bottom: 16px;
    span {
      font-weight: bold;
    }
  }
`;

const RequestInfoSection = styled.div`
  margin: 0 24px 40px 24px;
  background: ${colors.navPeach};
  box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);
  border-radius: 12px;
  text-align: center;
  padding: 16px;
  color: ${colors.smallGreyText};
  max-width: 772px;
  @media screen and (min-width: ${sizes.tablet}) {
    width: 80%;
    margin: 0 auto 40px auto;
  }
  h2 {
    margin-bottom: 8px;
  }
  .subheading {
    ${fonts.bigBody}
    color: ${colors.smallGreyText};
    margin-bottom: 16px;

    a {
      color: ${colors.smallGreyText};
      text-decoration: underline;
      font-weight: 500;

      &:hover {
        color: ${colors.smallGreyText};
        text-decoration: underline;
      }
    }
    .break {
      @media screen and (min-width: ${sizes.tablet}) {
        display: none;
      }
    }
  }
`;

const InfoForm = styled(Form)`
  text-align: left;
  max-width: 450px !important;
  margin: auto;
`;

const DesktopFlex = styled.div`
  @media screen and (min-width: ${sizes.tablet}) {
    display: flex;
    justify-content: space-evenly;
  }
`;

const ListItemWDropdown = ({ title, blurb }) => {
  const [active, setActive] = useState(false);

  return (
    <>
      <li onClick={() => setActive(!active)}>
        {title}
        <Icon name={active ? "caret up" : "caret down"} />
      </li>
      {active && <div className="li-blurb">{blurb}</div>}
    </>
  );
};

const Preplanning = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cemetery, setCemetery] = useState("");
  const [followUp, setFollowUp] = useState(false);
  const [submitRequest, { loading, data, err }] = useMutation(
    mutations.requestPricing
  );
  const handleSubmit = () => {
    submitRequest({
      variables: {
        name,
        phone,
        email,
        cemetery,
        followUp,
      },
    });
  };
  return (
    <div>
      <PreplanningBanner img={preplanning}>
        <h1>Preplanning</h1>
      </PreplanningBanner>
      <HeroDesc>
        Documenting final wishes and providing information for required legal
        documents in advance of need with or without prefunding.
      </HeroDesc>
      <WhyPreplan>
        <h2>Why preplan?</h2>
        <ul>
          {whyPreplanText.map(({ title, blurb }) => (
            <ListItemWDropdown id={title} title={title} blurb={blurb} />
          ))}
        </ul>
      </WhyPreplan>
      <WhyPreplan>
        <h2>Avoid the myths.</h2>
        <ul>
          {avoidMythsText.map(({ title, blurb }) => (
            <ListItemWDropdown id={title} title={title} blurb={blurb} />
          ))}
        </ul>
      </WhyPreplan>
      <CardContainer>
        <GetStartedCard>
          <img src={getStarted} />
          <GetStartedContent>
            <h2>Get Started</h2>
            <Subtitle>Take the first step</Subtitle>
            Whether you’d like to review information by phone, via e-mail or in
            person, we will streamline the process for you.
            <br />
            <br />
            <span>Preplanning consultations are always free of charge.</span>
            <ContactUs onClick={() => history.push("/contact")}>
              Contact Us
            </ContactUs>
          </GetStartedContent>
        </GetStartedCard>
      </CardContainer>
      <NotReadySection>
        <h2>Not ready yet?</h2>
        <div>
          Consider downloading and completing our{" "}
          <span>Funeral Planning Guide.</span> The planning guide is an easy way
          to start the process and have your information readily available at
          the time of need.
        </div>
        <DesktopFlex>
          <DownloadButton
            as="a"
            href="/Funeral_Planning_Guide.pdf"
            target="_blank"
          >
            Download
          </DownloadButton>
          {/* <DownloadButton
						as='a'
						href='https://forms.gle/eKNTMaaKMQxoh2eA7'
						target='_blank'
					>
						Complete Online
					</DownloadButton> */}
        </DesktopFlex>
      </NotReadySection>
      <RequestInfoSection>
        <h2>Request Pricing Info</h2>
        <div className="subheading">
          Request online or{" "}
          <span className="break">
            <br />
          </span>{" "}
          call us at <a href="tel:(414) 964-3111">(414) 964-3111</a>
        </div>
        {data && data.requestPricingInfo && (
          <div>Your request has been submitted. We will reach out shortly.</div>
        )}
        {!(data && data.requestPricingInfo) && (
          <InfoForm onSubmit={handleSubmit}>
            <Form.Input
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Form.Input
              type="email"
              required
              label="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Input
              type="tel"
              required
              label="Contact Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Form.Input
              label="Cemetery, if any"
              value={cemetery}
              onChange={(e) => setCemetery(e.target.value)}
            />
            <Form.Checkbox
              label="Would you like us to follow up?"
              checked={followUp}
              onChange={() => setFollowUp(!followUp)}
            />
            <FormSubmitButton type="submit">Request</FormSubmitButton>
          </InfoForm>
        )}
      </RequestInfoSection>
    </div>
  );
};
export default Preplanning;
