import React, { useState, useEffect } from "react";
import {
  Banner,
  fonts,
  colors,
  HeroDesc,
  sizes,
} from "../styles/reusableStyles";
import leaves from "../media/Leaves.png";
import styled from "styled-components";
import debra from "../media/debra.png";
import tom from "../media/tom.png";
import kimberly from "../media/kimberly.png";
import junie_and_miles from "../media/junie_and_miles.png";
import mail from "../media/Mail.png";
import outside1 from "../media/outside1.png";
import outside2 from "../media/outside2.png";
import gathering from "../media/gathering.jpg";
import HaveQuestionsCTA from "./HaveQuestionsCTA";
import map from "../media/OrangeMap.png";

const AboutBanner = styled(Banner)`
  h1 {
    color: white;
  }
`;

const Staff = styled.div`
  @media screen and (min-width: ${sizes.tablet}) {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: auto;
  }
`;

const ViewToggle = styled.div`
  @media screen and (min-width: ${sizes.tablet}) {
    margin-bottom: 40px;
  }
  max-width: 600px;
  margin: auto;
  margin-bottom: 24px;
  padding: 0px 16px;
  display: flex;
  justify-content: space-evenly;
  div {
    cursor: pointer;
    flex: 1;
    ${fonts.bigBody}
    color: ${colors.primaryGreen};
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    &.active {
      border-bottom: solid 2px ${colors.primaryGreen};
    }
    text-transform: uppercase;
    text-align: center;
  }
`;

const Facility = styled.div`
  text-align: center;
`;
const FacilityImage = styled.img`
  margin-bottom: 24px;
  width: 100%;
  max-width: 800px;
  @media screen and (min-width: ${sizes.tablet}) {
    margin: auto;
    margin-bottom: 40px;
    display: block;
  }
`;

const PersonCard = styled.div`
  margin: 0 50px;
  margin-bottom: 48px;
  img {
    margin-bottom: 8px;
    width: 100%;
  }
  text-align: center;

  @media screen and (min-width: ${sizes.tablet}) {
    flex: 1 0 34%;
    max-width: 300px;
  }
`;

const Name = styled.h3`
  color: ${colors.primaryGreen};
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-family: "DM Serif Text";
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 8px;
`;

const Email = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  width: fit-content;

  img {
    height: 14px;
    width: unset;
    margin: 0;
    margin-right: 8px;
  }

  span {
    text-decoration: underline;
    ${fonts.caption}
  }

  a {
    text-decoration: underline;
    color: ${colors.smallGreyText};
    ${fonts.caption}
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
`;

const Address = styled.div`
  color: ${colors.primaryOrange};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  @media screen and (min-width: ${sizes.tablet}) {
    margin-top: -20px;
  }
`;

const Icon = styled.img`
  height: 21px;
`;
const Text = styled.span`
  ${fonts.smallBody}
  padding-left: 10px;
  max-width: 200px;
  text-align: center;
`;

const Quote = styled.div`
  margin: auto;
  font-family: "DM Serif Text";
  font-style: italic;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #494d49;
  margin-bottom: 48px;
  max-width: 772px;
`;

const About = () => {
  const [selected, setSelected] = useState(
    window.location.pathname.includes("facility") ? 1 : 0
  );
  const path = window.location.pathname;
  useEffect(() => {
    setSelected(path.includes("facility") ? 1 : 0);
  }, [path]);
  return (
    <div>
      <AboutBanner img={leaves}>
        <h1>About Us</h1>
      </AboutBanner>
      <HeroDesc>
        {selected === 0
          ? "Our team has been working together to serve the community for over 20 years. Our experienced staff will guide you through every step including religious rituals, service selection and headstone placement."
          : "Serving the community for over 70 years."}
      </HeroDesc>
      {selected === 1 && (
        <Address>
          <Icon src={map} />
          <Text>4750 N. Santa Monica Blvd. Whitefish Bay, WI 53211</Text>
        </Address>
      )}
      <ViewToggle>
        <div
          className={selected === 0 ? "active" : ""}
          onClick={() => setSelected(0)}
        >
          Our Staff
        </div>
        <div
          className={selected === 1 ? "active" : ""}
          onClick={() => setSelected(1)}
        >
          Our Facility
        </div>
      </ViewToggle>
      {selected === 0 && (
        <>
          <Staff>
            <PersonCard>
              <img alt="Debra Marcus Watton - President" src={debra} />
              <Name>Debra Marcus Watton</Name>
              <Title>President</Title>
              <Email>
                <img alt="email icon" src={mail} />
                <a href="mailto:debra@goodmanbensman.com">
                  debra@goodmanbensman.com
                </a>
              </Email>
            </PersonCard>
            <PersonCard>
              <img alt="Tom Andrus - Licensed Funeral Director" src={tom} />
              <Name>Tom Andrus</Name>
              <Title>Licensed Funeral Director</Title>
              <Email>
                <img alt="email icon" src={mail} />
                <a href="mailto:tom@goodmanbensman.com">
                  tom@goodmanbensman.com
                </a>
              </Email>
            </PersonCard>

            <PersonCard>
              <img alt="Kimberly Ann Elz - Office Manager" src={kimberly} />
              <Name>Kimberly Ann Elz</Name>
              <Title>Office Manager</Title>
              <Email>
                <img alt="email icon" src={mail} />
                <a href="mailto:kimberly@goodmanbensman.com">
                  kimberly@goodmanbensman.com
                </a>
              </Email>
            </PersonCard>
            <PersonCard>
              <img
                alt="Junie and Miles - Certified Therapy Dogs"
                src={junie_and_miles}
              />
              <Name>Junie and Miles</Name>
              <Title>Certified Therapy Dogs</Title>
            </PersonCard>
          </Staff>
          <Quote>
            “Far and away the best prize that life has to offer is the chance to
            work hard at work worth doing.” <br />- Theodore Roosevelt
          </Quote>
          <HaveQuestionsCTA />
        </>
      )}
      {selected === 1 && (
        <Facility>
          <FacilityImage src={outside1} />
          <FacilityImage src={outside2} />
          <FacilityImage src={gathering} />
          <HaveQuestionsCTA />
        </Facility>
      )}
    </div>
  );
};

export default About;
