import React from 'react';
import serviceOptions2 from '../media/serviceOptions2.png';
import {
	Banner,
	HeroDesc,
	colors,
	fonts,
	StandardButton,
	sizes,
} from '../styles/reusableStyles';
import styled from 'styled-components';
import chapel from '../media/chapel.png';
import graveside from '../media/burialx3.png';
import memorial from '../media/memorial.png';
import immediate from '../media/immediate.png';
import greenBurial from '../media/greenBurial.png';

import HaveQuestionsCTA from './HaveQuestionsCTA';
import { useHistory } from 'react-router';

const ServicesBanner = styled(Banner)`
	/* height: unset;
	width: 100%;
	height: 0;
	padding-top: 63.466%;  hack for showing full image if needed*/
	background: ${({ img }) =>
		`linear-gradient(0deg, rgba(73, 77, 73, 0.2), rgba(73, 77, 73, 0.2)), url(${img})`};
	background-size: cover;
	background-position: center;
	mix-blend-mode: darken;
	h1 {
		color: white;
	}
`;

const ServiceCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 24px;
	margin-bottom: 40px;
	@media screen and (min-width: ${sizes.tablet}) {
		margin: 0 40px 40px 40px;
	}
	text-align: center;
	h2 {
		margin-bottom: 8px;
		color: ${colors.primaryGreen};
		@media screen and (min-width: ${sizes.tablet}) {
			font-size: 32px;
			line-height: 44px;
		}
	}
`;

const CardContent = styled.div`
	box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	@media screen and (min-width: ${sizes.tablet}) {
		max-width: 772px;
	}
`;

const CardImage = styled.img`
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	width: 100%;
	&.graveside {
		object-position: bottom;
	}
	@media screen and (min-width: ${sizes.tablet}) {
		max-width: 772px;
		max-height: 280px;
		object-fit: cover;
	}
`;

const CardBlurb = styled.div`
    background: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 16px;
    ${fonts.bigBody}
    color: ${colors.smallGreyText};
    text-align: ${({ hasButton }) => (hasButton ? 'center' : 'left')};

    span{
        color: ${colors.primaryOrange}
    }
    
    div{
        text-align: left;
    }
`;

const LearnMoreButton = styled(StandardButton)`
	background-color: ${colors.primaryGreen};
	border-color: ${colors.primaryGreen};
	margin-bottom: 24px;
	margin-top: 40px;
	box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);
`;

const ServiceOptions = () => {
	const history = useHistory();
	return (
		<div>
			<ServicesBanner img={serviceOptions2}>
				<h1>Service Options</h1>
			</ServicesBanner>
			<HeroDesc>
				Our experienced staff can answer all of your questions about
				religous rites and rituals. Serving all faiths, we will help you
				select the service option to best honor your loved one.
			</HeroDesc>
			<ServiceCard>
				<h2>Chapel Service</h2>
				<CardContent>
					<CardImage src={chapel} />
					<CardBlurb>
						A chapel service can take place in our funeral home in
						Whitefish Bay, at your place of worship or at any other
						location of your choosing. It may be private or open to
						friends and extended family. <br />
						<br />
						Burial can immediately follow the service with a smaller
						gathering at the grave site.
					</CardBlurb>
				</CardContent>
			</ServiceCard>
			<ServiceCard>
				<h2>Graveside Service</h2>
				<CardContent>
					<CardImage className='graveside' src={graveside} />
					<CardBlurb>
						A graveside funeral takes place at the grave site
						surrounded by loved ones and, if desired, clergy. It is
						immediately followed by burial of the casket or urn.{' '}
						<br />
						<br />
						It may be private for immediate family or open to
						friends and other relatives.
					</CardBlurb>
				</CardContent>
			</ServiceCard>
			<ServiceCard>
				<h2>Memorial Service</h2>
				<CardContent>
					<CardImage src={memorial} />
					<CardBlurb>
						A memorial service can be held at our chapel, your place
						of worship or another location of your choosing. <br />
						<br />
						Typically, no casket is present, so it can be held any
						time before or after cremation or private burial. It
						also provides an opportunity to gather if burial takes
						place in another city.
					</CardBlurb>
				</CardContent>
			</ServiceCard>
			<ServiceCard>
				<h2>Immediate Burial/Cremation</h2>
				<CardContent>
					<CardImage src={immediate} />
					<CardBlurb>
						A funeral ceremony is not required. Families may elect a
						direct cremation without a ceremony or an immediate
						burial for their loved one. <br />
						<br />
						For an immediate burial, only the funeral director and
						cemetery caretakers tend to the burial, but family may
						visit the grave afterward.
					</CardBlurb>
				</CardContent>
			</ServiceCard>
			<ServiceCard>
				<h2>Green Burial</h2>
				<CardContent>
					<CardImage src={greenBurial} />
					<CardBlurb hasButton>
						<div>
							Green burial is a simple but dignified return to
							earth. It offers an environmentally sustainable
							option without concrete, chemicals or unnatural
							processes.
						</div>
						<LearnMoreButton
							onClick={() => history.push('/green_burial')}
						>
							Learn More
						</LearnMoreButton>
					</CardBlurb>
				</CardContent>
			</ServiceCard>
			<HaveQuestionsCTA />
		</div>
	);
};

export default ServiceOptions;
