import React, { useState } from "react";
import styled from "styled-components";
import { fonts, colors, StandardButton, sizes } from "../styles/reusableStyles";
import { Input, Loader, Dimmer } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import mutations from "../helpers/mutations";
import ReCAPTCHA from "react-google-recaptcha";
const CTASection = styled.div`
  padding: 24px;
  background: ${colors.navPeach};
  text-align: center;
  margin-bottom: 24px;

  .success {
    ${fonts.smallBody}
    font-weight: bold;
    color: ${colors.smallGreyText};
  }

  @media screen and (min-width: ${sizes.tablet}) {
    display: none;
  }
`;

const CTATitle = styled.div`
  ${fonts.subtitle}
  color: ${colors.secondaryOrange};
  height: 32px;
  line-height: 32px;
`;

const CTACaption = styled.div`
  margin-bottom: 8px;
  ${fonts.smallBody}
  color: ${colors.smallGreyText};
`;

const CTAInput = styled(Input)`
  &.ui.input {
    display: block;
    margin-bottom: 24px;

    input {
      border: 1px solid rgba(203, 204, 203, 0.5);
      width: 100%;
      border-radius: 12px;
      height: 48px;
    }
  }
`;

const StayConnectedCTA = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [subscribe, { loading, err, data }] = useMutation(mutations.subscribe);
  const handleSubscribe = () => {
    if (!email) {
      setEmailError(true);
      return;
    } else {
      subscribe({
        variables: {
          email,
        },
      });
    }
  };
  const handleRecaptchaSuccess = (token) => {
    if (token) setCaptchaSuccess(true);
  };
  return (
    <>
      <Dimmer active={loading}>
        <Loader active={loading} />
      </Dimmer>
      <CTASection>
        <CTATitle>Stay Connected</CTATitle>
        <CTACaption>Receive an email when a new obituary is added.</CTACaption>
        {!(data && data.subscribe) && (
          <>
            <CTAInput
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => {
                setEmailError(false);
                setEmail(e.target.value);
              }}
              error={emailError}
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
              onChange={handleRecaptchaSuccess}
            />
            {captchaSuccess && (
              <StandardButton onClick={handleSubscribe}>
                Subscribe
              </StandardButton>
            )}
          </>
        )}

        {data && data.subscribe && (
          <div className="success">You have successfully subscribed!</div>
        )}
      </CTASection>
    </>
  );
};

export default StayConnectedCTA;
