import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors, fonts, sizes } from "../styles/reusableStyles";
import { Modal, Input, Divider } from "semantic-ui-react";
import BannerModal from "./BannerModal";
import heroImage from "../media/MobileHerox3.png";
import immediateNeed from "../media/immediateNeed.png";
import preplanningImg from "../media/preplanning.png";
import greenBurial from "../media/greenBurial.png";
import griefSupport from "../media/griefx3.png";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";
import moment from "moment";
import queries from "../helpers/queries";
import StayConnectedCTA from "./StayConnectedCTA";

const CardDivider = styled(Divider)`
  margin: 8px 40% !important;
`;
const HomeContainer = styled.div``;
const BannerMessage = styled.div`
  max-width: 1024px;
  margin: auto;
  background-color: ${colors.navPeach};
  padding: 8px 15px;
  ${fonts.smallBody}
  text-align: center;
  button {
    cursor: pointer;
    background-color: unset;
    border: unset;
    padding: unset;
    font: unset;
    text-decoration: underline;
    &:focus {
      outline: none;
    }
  }
  span {
    white-space: nowrap;
  }
`;
const Hero = styled.img`
  mix-blend-mode: darken;
  width: 100%;
  max-width: 1024px;
  display: block;
  margin: auto;
  /* height: 240px; */
  margin-bottom: 24px;
  height: 100%;
  max-height: 420px;
  object-fit: cover;
`;
const HeroButton = styled.a`
  cursor: pointer;
  background: ${colors.primaryOrange};
  border: 2px solid ${colors.primaryOrange};
  border-radius: 48px;
  height: 48px;
  padding: 8px 16px;
  ${fonts.button}
  color: white;
  display: inline-block;
  box-sizing: border-box;
  line-height: 28px;
  margin-top: 24px;

  @media screen and (min-width: ${sizes.tablet}) {
    min-width: 192px;
  }

  &:hover {
    color: white;
  }
`;
const HeroTitle = styled.h2`
  color: ${colors.smallGreyText};
  margin: 0 16px 24px 16px;
  text-align: center;
  @media screen and (min-width: ${sizes.tablet}) {
    margin-bottom: 40px;
  }

  span {
    white-space: nowrap;
  }
`;

const HomeCardRow = styled.div`
  @media screen and (min-width: ${sizes.tablet}) {
    max-width: ${sizes.max};
    margin: auto;
    display: flex;
    margin-bottom: 32px;
  }
`;

const HomeCardSection = styled.div`
  /* margin-bottom: 24px; */
  margin: 0 24px 24px 24px;
  text-align: center;
  @media screen and (min-width: ${sizes.tablet}) {
    max-width: calc(50% - 64px);
    &.left {
      margin-left: 48px;
      margin-right: 32px;
    }
    &.right {
      margin-left: 0px;
      margin-right: 48px;
    }
  }

  h2 {
    color: ${colors.primaryGreen};
  }

  &.obituaries {
    @media screen and (min-width: ${sizes.tablet}) {
      width: 80%;
      max-width: 700px;
      margin: auto;
      margin-bottom: 40px;
    }
    h2 {
      margin-bottom: 16px;
    }
  }
`;
const Card = styled.div`
  box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);
  border-radius: 12px;

  img {
    max-width: 100%;
    border-radius: 12px 12px 0 0;
  }
`;

const CardContent = styled.div`
  background-color: white;
  padding: 16px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  h2 {
    margin-bottom: 8px;
  }
  @media screen and (min-width: ${sizes.tablet}) {
    height: 240px;
  }
`;

const CardTitle = styled.div`
  ${fonts.subtitle}
  color: ${colors.secondaryOrange};
  margin-bottom: 8px;
`;

const CardText = styled.div`
  ${fonts.bigBody}
  color: ${colors.greyText};
  margin-bottom: 8px;

  span {
    white-space: nowrap;
  }
`;

const CardCTA = styled.div`
  color: ${colors.smallGreyText};
  ${fonts.bigBody}
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  a {
    cursor: pointer;
    color: ${colors.smallGreyText};
    text-decoration: underline;
  }
  &.phone {
    color: ${colors.secondaryOrange};
    a {
      color: ${colors.secondaryOrange};
    }
  }
`;

const ObituaryCard = styled.div`
  background: #fff4e7;
  box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.1);
  border-radius: 12px;
  padding: 16px;
  min-height: 467px;
`;

const ObitInput = styled(Input)`
  &.ui.input {
    display: block;
    margin-bottom: 16px;

    input {
      border: 1px solid ${colors.navPeach};
      width: 100%;
      border-radius: 12px;
      height: 48px;
    }
  }
`;
const NameLink = styled.a`
  text-decoration: underline;
  ${fonts.smallBody}
  color: ${colors.primaryOrange};
  font-weight: bold;
  &:hover {
    color: ${colors.primaryOrange};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ObitLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  span {
    ${fonts.smallBody}
    color: black;
  }
`;

const MoreLink = styled.a`
  ${fonts.bigBody}
  font-weight: bold;
  color: ${colors.smallGreyText};
  text-decoration: underline;
  &:hover {
    color: ${colors.smallGreyText};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const PageHeading = styled.h1`
  display: none;
  @media screen and (min-width: ${sizes.tablet}) {
    display: block;
  }
  color: ${colors.primaryGreen};
  margin-bottom: 40px;
  text-align: center;
`;

const ObitItem = ({ obit }) => {
  const history = useHistory();

  return (
    <ObitLine>
      <NameLink onClick={() => history.push(`/obituaries/${obit.id}`)}>
        {obit.name}
      </NameLink>
      <span>
        {moment(parseInt(obit.deathDate)).utcOffset(0).format("M/D/YYYY")}
      </span>
    </ObitLine>
  );
};
const Home = () => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const { data, fetchMore } = useQuery(queries.getObituaries, {
    variables: {
      limit: 7,
      offset: 0,
    },
  });
  const [fullyFetched, setFullyFetched] = useState(false);
  const [search, setSearch] = useState("");
  const obituaries = data
    ? data.obituaries
        .filter((o) => o.name.toLowerCase().includes(search.toLowerCase()))
        .slice(0, 7)
    : [];

  useEffect(() => {
    if (search && !fullyFetched) {
      fetchMore({
        variables: {
          limit: undefined,
          offset: undefined,
        },
      });
      setFullyFetched(true);
    }
  }, [search, fullyFetched, fetchMore]);

  return (
    <>
      <Modal
        closeIcon
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={<BannerModal />}
      />
      <HomeContainer>
        {/* <BannerMessage>
          <button onClick={() => setModalOpen(true)}>Learn more</button> about
          how we are responding to the <span> COVID-19 </span>
          crisis.
        </BannerMessage> */}
        <Hero src={heroImage} />
        <HeroTitle>
          <div>
            Serving the community with compassion,{" "}
            <span>dignity and respect.</span>
          </div>
          <HeroButton onClick={() => history.push("/contact")}>
            Contact Us
          </HeroButton>
        </HeroTitle>
        <PageHeading>Our Services</PageHeading>
        <HomeCardRow>
          <HomeCardSection className="left">
            <Card>
              <img
                alt="Immediate Funeral Planning - We're here 24/7"
                src={immediateNeed}
              />

              <CardContent>
                <h2>Immediate Need</h2>
                <CardDivider />
                <CardTitle>Available when you need us</CardTitle>
                <CardText>
                  We're available 24/7. <span>Please call us immediately</span>{" "}
                  if a death has occurred.
                </CardText>
                <CardCTA className="phone">
                  Call <a href="tel:414-964-3111">(414) 964-3111</a>
                </CardCTA>
              </CardContent>
            </Card>
          </HomeCardSection>
          <HomeCardSection className="right">
            <Card>
              <img alt="Preplanning Funerals" src={preplanningImg} />
              <CardContent>
                <h2>Preplanning</h2>
                <CardDivider />
                <CardTitle>Document Your Final Wishes</CardTitle>
                <CardText>
                  Our experienced staff will guide you through each decision.
                  Consultations are free and without obligation.
                </CardText>
                <CardCTA>
                  <a onClick={() => history.push("/services/preplanning")}>
                    Learn More
                  </a>
                </CardCTA>
              </CardContent>
            </Card>
          </HomeCardSection>
        </HomeCardRow>
        <HomeCardRow>
          <HomeCardSection className="left">
            <Card>
              <img alt="Green Burial" src={greenBurial} />
              <CardContent>
                <h2>Green Burial</h2>
                <CardDivider />
                <CardTitle>A Natural Return to Earth</CardTitle>
                <CardText>
                  Green Burial is a natural, affordable burial option using only
                  biodegradable materials.
                </CardText>
                <CardCTA>
                  <a onClick={() => history.push("/green_burial")}>
                    Learn More
                  </a>
                </CardCTA>
              </CardContent>
            </Card>
          </HomeCardSection>
          <HomeCardSection className="right">
            <Card>
              <img alt="Grief Support" src={griefSupport} />
              <CardContent>
                <h2>Grief Support</h2>
                <CardDivider />
                <CardTitle>Get The Support You Need</CardTitle>
                <CardText>
                  By providing a variety of resources, we hope to help loved
                  ones cope with the pain of loss.
                </CardText>
                <CardCTA>
                  <a onClick={() => history.push("/services/grief_support")}>
                    Learn More
                  </a>
                </CardCTA>
              </CardContent>
            </Card>
          </HomeCardSection>
        </HomeCardRow>
        <HomeCardSection className="obituaries">
          <h2>Obituaries</h2>
          <ObituaryCard>
            <ObitInput
              placeholder="Search by first or last name"
              icon="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {obituaries
              .filter((o) => o.visible)
              .map((o, i) => (
                <ObitItem key={i} obit={o} />
              ))}
            <MoreLink onClick={() => history.push("/obituaries")}>
              View More
            </MoreLink>
          </ObituaryCard>
        </HomeCardSection>
        <StayConnectedCTA />
      </HomeContainer>
    </>
  );
};

export default Home;
