const FAQs = [
	{
		question: 'What should we do when death occurs?',
		answer:
			'Please call the funeral home at 414-964-3111.  We are available at all times including holidays.  Our funeral director will be notified immediately and will work with you by phone, in person or via e-mail to make all arrangements including transportation of the deceased. ',
	},
	{
		question:
			'We live out of town. What should we do when death occurs outside of Milwaukee?',
		answer:
			'Please call the funeral home at 414-964-3111 as soon as possible.  There is no need to contact a local funeral home.  Our funeral director will be notified immediately and will handle the arrangements for you, including transportation of the deceased to Milwaukee. ',
	},
	{
		question:
			'We are an interfaith family. Can we call upon Goodman-Bensman for all of our family members?',
		answer:
			'Yes.  We serve families of all faiths and families who do not practice any religion.  Our staff can assist loved ones in making final arrangements that everyone will be comfortable with including traditional burial, cremation and Green Burial.  We can also help with the selection of clergy for unaffiliated families.  All faiths are welcome.',
	},

	{
		question: 'Can we arrange for a funeral before death occurs?',
		answer:
			'Yes.  We have a certified preneed consultant on staff to answer your questions by phone, via e-mail or in person.  You can prepay for funeral arrangements through an insurance policy or simply create a file that includes information required for legal documents as well as your personal wishes. Consultations are free.',
	},
	{
		question: 'Is the funeral home wheelchair accessible?',
		answer:
			'Yes.  There are doors on the north and south side of our facility that are wheelchair accessible as well as designated parking spaces. You may also contact the funeral home prior to arriving, and we will do our best to assist you.',
	},
	{
		question:
			'We are not religious. Can someone explain the appropriate religious funeral rites and rituals?',
		answer:
			'Yes.  Our experienced staff can explain Jewish and Christian based funeral rites and rituals so that you can make informed decisions.  It is important to us that you are comfortable with the funeral arrangements selected, especially if your family members are of different faiths.  We can also help find clergy for unaffiliated families.',
	},
	{
		question: 'Can a payment plan be arranged for financial hardship?',
		answer:
			'Yes.  We will respect the privacy and confidentiality of those experiencing financial hardship.  In addition, we accept Wisconsin Funeral and Cemetery Aid payments for those who qualify.',
	},
	{
		question: 'Who operates Goodman-Bensman Funeral Home?',
		answer:
			'Goodman-Bensman Funeral Home is owned by a Wisconsin limited liability company made up of local individuals and families.  We employ a skilled staff consisting of a licensed funeral director, certified preneed consultant and other support personnel who are available at all times to assist you.',
	},
];

export default FAQs;
