import React from 'react';
import styled from 'styled-components';
import {
	Banner,
	HeroDesc,
	colors,
	fonts,
	StandardButton,
	DownloadButton,
	sizes,
} from '../styles/reusableStyles';
import monuments from '../media/monumentsx3.png';
import ImageGallery from 'react-image-gallery';
import diebase1 from '../media/diebase1.png';
import diebase2 from '../media/diebase2.png';
import diebase3 from '../media/diebase3.png';
import slant1 from '../media/slant1.png';
import slant2 from '../media/slant2.png';
import slant3 from '../media/slant3.png';
import bevel1 from '../media/bevel1.png';
import bevel2 from '../media/bevel2.png';
import bevel3 from '../media/bevel3.png';
import grass1 from '../media/grass1.png';
import grass2 from '../media/grass2.png';
import grass3 from '../media/grass3.png';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useHistory } from 'react-router';

const ImmediateNeedBanner = styled(Banner)`
	background: ${({ img }) =>
		`linear-gradient(0deg, rgba(73, 77, 73, 0.2), rgba(73, 77, 73, 0.2)), url(${img})`};
	background-size: cover;
	background-position: 0 100%;
	h1 {
		color: white;
	}
`;

const LetUsHelpCard = styled.div`
	box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.1);
	border-radius: 12px;
	background: white;
    padding: 16px;
    text-align: center;
	margin: 0 16px 40px 16px;
	
	max-width: 772px;
	@media screen and (min-width: ${sizes.tablet}){
		width: 80%;
		margin: 0 auto 40px auto;
	}
    
    
    h2{
        color: ${colors.primaryGreen};
        height: 40px;
        line-height: 40px;
        margin-bottom: 8px;
    }
    div{
        ${fonts.bigBody}
        color: ${colors.smallGreyText};
        margin-bottom: 16px;
		text-align: left;
		@media screen and (min-width: ${sizes.tablet}){
			max-width: 610px;
			margin: auto;
			margin-bottom: 16px;
			text-align: center;

		}
        span{
            font-weight: bold;
        }
    }
`;

const ContactUsButton = styled(StandardButton)`
	background: ${colors.primaryGreen};
	border: 2px solid ${colors.primaryGreen};
	display: inline-block;
	line-height: 28px;
	min-width: 175px;

	&:hover {
		color: white;
	}
`;

const InfoSections = styled.div`
	margin: 0 16px;
	margin-bottom: 24px;
	text-align: center;

	h2 {
		color: ${colors.primaryGreen};
		margin-bottom: 8px;
	}
	div {
		text-align: left;
		${fonts.bigBody};
		color: ${colors.smallGreyText};
		margin-bottom: 24px;
		span {
			font-weight: bold;
		}
	}
	@media screen and (min-width: ${sizes.tablet}) {
		width: 80%;
		max-width: 772px;
		margin: 0 auto 48px auto;
	}
`;

const MonumentSection = styled.div`
	margin-bottom: 40px;

	&.grass {
		h3 {
			margin-bottom: -24px;
		}
		.image-gallery {
			margin-bottom: -24px;
		}
	}

	h3 {
		text-align: center;
		color: ${colors.secondaryOrange};
		margin-bottom: 16px;
	}

	div {
		${fonts.bigBody}
		padding: 0px 16px;
		color: ${colors.smallGreyText};
	}

	.image-gallery {
		padding: 0px;
		margin-bottom: 16px;
		div {
			padding: 0px;
		}
	}

	@media screen and (min-width: ${sizes.tablet}) {
		width: 80%;
		max-width: 772px;
		margin: 0 auto 40px auto;
	}
`;

const dbImages = [
	{ original: diebase1 },
	{ original: diebase2 },
	{ original: diebase3 },
];

const slantImages = [
	{ original: slant1 },
	{ original: slant2 },
	{ original: slant3 },
];

const bevelImages = [
	{ original: bevel1 },
	{ original: bevel2 },
	{ original: bevel3 },
];

const grassImages = [
	{ original: grass1 },
	{ original: grass2 },
	{ original: grass3 },
];

const Monuments = () => {
	const history = useHistory();
	return (
		<div>
			<ImmediateNeedBanner img={monuments}>
				<h1>Monuments</h1>
			</ImmediateNeedBanner>
			<HeroDesc>
				It is a great gesture of respect to mark the grave of a loved
				one.
			</HeroDesc>
			<InfoSections>
				<h2>Monument Selection</h2>
				<div>
					Our extensive selection features a wide variety of shapes,
					sizes and granite colors. Additionally, we can assist with
					English, Hebrew or Russian inscriptions and cemetery
					paperwork. Headstones are usually placed anytime after the
					burial, but within the first year.
				</div>
			</InfoSections>
			<MonumentSection>
				<h3>Die and Base Markers</h3>
				<ImageGallery
					items={dbImages}
					showThumbnails={false}
					showFullscreenButton={false}
					showPlayButton={false}
					// showBullets={true}
				/>
				<div>
					Typically, the tallest monument style, the die sits
					vertically on a horizontal base. Available in a variety of
					sizes, colors and shapes, including double and single
					options.
				</div>
			</MonumentSection>
			<MonumentSection>
				<h3>Slant Markers</h3>
				<ImageGallery
					items={slantImages}
					showThumbnails={false}
					showFullscreenButton={false}
					showPlayButton={false}
					// showBullets={true}
				/>
				<div>
					Triangular in shape, this headstone is typically 10" deep at
					the bottom and 16" high in the center. It is available in
					double and single options, several granite colors and can be
					placed on a horizontal base for additional height.
				</div>
			</MonumentSection>
			<MonumentSection>
				<h3>Bevel Markers</h3>
				<ImageGallery
					items={bevelImages}
					showThumbnails={false}
					showFullscreenButton={false}
					showPlayButton={false}
					// showBullets={true}
				/>
				<div>
					This headstone is typically 12" deep. Its height ranges from
					8 to 12" in the back with a 2" drop toward the front. It is
					available in a single or double width in a variety of
					granite colors.
				</div>
			</MonumentSection>
			<MonumentSection className='grass'>
				<h3>Flat (Grass) Markers</h3>
				<ImageGallery
					items={grassImages}
					showThumbnails={false}
					showFullscreenButton={false}
					showPlayButton={false}
					// showBullets={true}
				/>
				<div>
					Flat markers are usually set flush with the ground. They are
					typically rectangular with a 12" depth and a height of 4".
					They are available in single or double widths in a variety
					of granite colors. Some cemeteries require flush markers in
					certain sections.
				</div>
			</MonumentSection>

			<LetUsHelpCard>
				<h2>Begin the process</h2>
				<div>
					Whether you’d like to match an existing family headstone,
					add an inscription or create a new monument, our experienced
					staff can help. All of the steps can be completed via mail
					or e-mail, or we can discuss the options in person.
				</div>
				<ContactUsButton onClick={() => history.push('/contact')}>
					Contact Us
				</ContactUsButton>
			</LetUsHelpCard>
		</div>
	);
};

export default Monuments;
