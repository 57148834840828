import React, { useState, useRef } from "react";
import { useParams, useHistory } from "react-router";
import styled from "styled-components";
import { colors, fonts, StandardButton, sizes } from "../styles/reusableStyles";
import obituaryLeaf from "../media/obituaryLeaf.png";
import obitMenorah from "../media/obit-menorah.png";
import obitFlower from "../media/obit-flower.png";
import obitSky from "../media/obit-sky.png";
import obitWater from "../media/obit-water.png";
import shareIcon from "../media/shareIconWhite.png";
import printIcon from "../media/printIconWhite.png";
import mailIcon from "../media/mailIcon.png";
import fbIcon from "../media/fbIcon.png";
import { Form, Dimmer, Loader, Modal, Divider } from "semantic-ui-react";
import queries from "../helpers/queries";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import mutations from "../helpers/mutations";
import { useReactToPrint } from "react-to-print";
import ObituaryShareModal from "./ObituaryShareModal";
import { FacebookShareButton, EmailShareButton } from "react-share";

const MainImageContainer = styled.div`
  text-align: center;
  background-color: ${colors.navPeach};
  height: 235px;
  img {
    width: 235px;
    height: 235px;
    margin-top: 70px;
    object-fit: cover;
  }

  &.menorah {
    img {
      width: 200px;
      height: 200px;
      margin-top: 16px;
    }
  }
`;
const NameNavContainer = styled.div`
  text-align: center;
  background-color: ${colors.offWhite};
  padding-top: 70px;

  &.menorah {
    padding-top: 0;
  }

  h1 {
    color: ${colors.primaryOrange};
    margin-top: 16px;
    margin-bottom: 4px;
  }

  @media screen and (min-width: ${sizes.tablet}) {
    width: 80%;
    max-width: 772px;
    margin: auto;
  }
`;

const Dates = styled.h3`
  /* ${fonts.bigBody} */
  color: ${colors.smallGreyText};
  margin-bottom: 32px;
`;

const ViewToggle = styled.div`
  display: flex;
  margin: 0 16px;
  div {
    cursor: pointer;
    flex: 1;
    ${fonts.bigBody}
    color: ${colors.primaryGreen};
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    &.active {
      border-bottom: solid 2px ${colors.primaryGreen};
    }
    text-transform: uppercase;
  }
`;
const ContentContainer = styled.div`
  background: white;
  padding: 24px 16px;
  @media screen and (min-width: ${sizes.tablet}) {
    width: 80%;
    max-width: 772px;
    margin: auto;
    margin-bottom: 36px;
  }
`;

const Description = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #cbcccb;
  margin-bottom: 24px;
  ${fonts.bigBody}
  color: ${colors.smallGreyText};
  white-space: pre-wrap;
`;

const ObitInfoSection = styled.div`
  margin-bottom: 32px;
  a {
    color: ${colors.secondaryOrange};
    text-decoration: underline;
    ${fonts.bigBody}
    font-weight: bold;
    overflow-wrap: break-word;
    word-wrap: break-word;

    &:hover {
      color: ${colors.secondaryOrange};
      text-decoration: underline;
    }
  }
  .obit-type {
    ${fonts.bigBody}
    color: ${colors.smallGreyText};
  }
  div {
    margin-bottom: 8px;
    ${fonts.bigBody}
    color: ${colors.smallGreyText};
  }
  h3 {
    color: ${colors.smallGreyText};
    margin-bottom: 16px;
    @media screen and (min-width: ${sizes.tablet}) {
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 33px;
    }
  }

  @media screen and (min-width: ${sizes.tablet}) {
    flex: 1;
    padding-right: 16px;
  }
`;

const Address = styled.div`
  white-space: pre-wrap;
`;
const ContactInfo = styled.div`
  margin-bottom: 24px;
  ${fonts.bigBody}
  color: ${colors.smallGreyText};
  span {
    font-weight: bold;
  }
`;

const PhoneSpan = styled.a`
  cursor: pointer;
  color: ${colors.secondaryOrange};
  font-weight: bold;
  text-decoration: underline;
  white-space: nowrap;
  &:hover {
    color: ${colors.secondaryOrange};
    text-decoration: underline;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 24px;
`;

export const BottomButton = styled(StandardButton)`
  &.fb {
    display: none;
  }
  &.email {
    display: none;
  }
  background-color: ${colors.primaryGreen};
  color: white;
  border-color: ${colors.primaryGreen};
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 21px;
    margin-right: 5px;
  }
  @media screen and (min-width: ${sizes.tablet}) {
    width: 180px;
    &.fb {
      display: flex;
    }
    &.email {
      display: flex;
    }
    &.share {
      display: none;
    }
  }
`;
const GuestBookContainer = styled.div`
  text-align: center;
  h3 {
    color: ${colors.smallGreyText};
    /* text-decoration: underline; */
    margin-bottom: 16px;
  }
`;

const Post = styled.div`
  text-align: left;
  background: ${colors.offWhite};
  box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.1);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 16px;
`;

const PostText = styled.div`
  white-space: pre-wrap;
  ${fonts.bigBody}
  color: black;
  margin-bottom: 8px;
`;

const PostCaption = styled.div`
  ${fonts.smallBody}
  font-style: italic;
  color: ${colors.smallGreyText};
`;

const SeeMoreLink = styled.a`
  display: block;
  margin-bottom: 24px;
  ${fonts.bigBody}
  color: ${colors.smallGreyText};
  text-decoration: underline;
  font-weight: bold;
  &:hover {
    color: ${colors.smallGreyText};
    text-decoration: underline;
  }
`;

const SignForm = styled.div`
  background: ${colors.navPeach};
  box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);
  border-radius: 12px;
  padding: 16px;
  h2 {
    color: ${colors.smallGreyText};
    margin-bottom: 16px;
    margin-top: 0;
  }
`;

const SignButton = styled(StandardButton)`
  min-width: 145px;
  margin-bottom: 16px;
`;

const FormCaption = styled.div`
  text-align: left;
  ${fonts.caption}
  color: #808080;
`;

const MemorialContribution = styled.div`
  div {
    margin-bottom: 16px;
    @media screen and (min-width: ${sizes.tablet}) {
      margin-bottom: 8px;
    }
  }
  .website-name-no-link {
    font-weight: bold;
  }
`;

const Shiva = styled.div`
  white-space: pre-wrap;
  margin-bottom: 24px !important;
  div,
  a {
    display: block;
    margin-bottom: 8px;
    @media screen and (min-width: ${sizes.tablet}) {
      margin-bottom: 4px;
    }
  }
`;

const ObitInfoRow = styled.div`
  @media screen and (min-width: ${sizes.tablet}) {
    display: flex;
    margin-bottom: 36px;
  }
`;

const Obituary = () => {
  const history = useHistory();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { id } = useParams();
  const { loading, _, data } = useQuery(queries.getObituaryById, {
    variables: {
      id,
    },
  });
  const obituary = data && data.getObituaryById ? data.getObituaryById : null;
  if (obituary && !obituary.visible) {
    history.push("/error");
  }
  const [activeView, setActiveView] = useState(0);
  const GuestBookPost = ({ post }) => {
    return (
      <Post>
        <PostText>{post.text}</PostText>
        <PostCaption>
          by {post.name} on{" "}
          {moment(parseInt(post.dateSubmitted)).format("M/D/YYYY")}
        </PostCaption>
      </Post>
    );
  };
  const [
    submitPost,
    { loading: postLoading, error: postError, data: postData },
  ] = useMutation(mutations.createPost);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [visualPosts, setVisualPosts] = useState(4);
  const [modalOpen, setModalOpen] = useState(false);

  const seeMorePosts = () => setVisualPosts(visualPosts + 4);

  const handleGuestbookSubmit = (e) => {
    e.preventDefault();
    submitPost({
      variables: {
        input: {
          name,
          email,
          text,
          guestBookId: obituary.guestBook.id,
        },
      },
    });
  };

  const getObitImage = () => {
    if (!obituary) return obitMenorah;
    if (obituary.imageAddress) return obituary.imageAddress;
    if (obituary.useMenorahImage) return obitMenorah;
    switch (obituary.id.charCodeAt(24) % 4) {
      case 0:
        return obituaryLeaf;
      case 1:
        return obitFlower;
      case 2:
        return obitSky;
      case 3:
        return obitWater;
    }
  };
  const obitImage = getObitImage();
  return (
    <>
      <Modal
        closeIcon
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={<ObituaryShareModal obit={obituary} />}
      />
      <Dimmer active={loading} />
      <Loader content="Loading Obituary" inverted active={loading} />
      {!loading && (
        <div ref={componentRef}>
          <MainImageContainer
            className={obitImage == obitMenorah ? "menorah" : ""}
          >
            <img src={obitImage} />
          </MainImageContainer>
          <NameNavContainer
            className={obitImage == obitMenorah ? "menorah" : ""}
          >
            <h1>{obituary.name}</h1>
            <Dates>
              {obituary.birthDate &&
                moment(parseInt(obituary.birthDate))
                  .utcOffset(0)
                  .format("MMMM D, YYYY") + " - "}
              {moment(parseInt(obituary.deathDate))
                .utcOffset(0)
                .format("MMMM D, YYYY")}
            </Dates>
            <ViewToggle>
              <div
                className={activeView == 0 ? "active" : ""}
                onClick={() => setActiveView(0)}
              >
                Obituary
              </div>
              <div
                className={activeView == 1 ? "active" : ""}
                onClick={() => setActiveView(1)}
              >
                GuestBook
              </div>
              <div
                className={activeView == 2 ? "active" : ""}
                onClick={() => setActiveView(2)}
              >
                Info
              </div>
            </ViewToggle>
          </NameNavContainer>
          <ContentContainer>
            {activeView == 0 && (
              <>
                <Description>{obituary.description}</Description>
                <ObitInfoRow>
                  <ObitInfoSection>
                    <h3>Service information:</h3>
                    {!obituary.service.isPrivate && (
                      <>
                        <div className="obit-type">
                          {" "}
                          {obituary.service.type}
                        </div>
                        {obituary.service.address && (
                          <Address>{obituary.service.address}</Address>
                        )}
                        {obituary.service.date && (
                          <div>
                            {moment
                              .tz(
                                parseInt(obituary.service.date),
                                "America/Chicago"
                              )
                              .format("M/D/YYYY h:mma")}
                          </div>
                        )}
                        {obituary.service.address && (
                          <a
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${obituary.service.address.replace(
                              " ",
                              "%20"
                            )}`}
                          >
                            Map to Service
                          </a>
                        )}
                      </>
                    )}
                    {obituary.service.isPrivate && <div>Private Service</div>}
                  </ObitInfoSection>
                  {obituary.cemeteryName && (
                    <ObitInfoSection>
                      <h3>Cemetery:</h3>
                      <div>{obituary.cemeteryName}</div>
                      {obituary.cemeteryAddress && (
                        <>
                          {" "}
                          <Address>{obituary.cemeteryAddress}</Address>
                          <a
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${obituary.cemeteryAddress.replace(
                              " ",
                              "%20"
                            )}`}
                          >
                            Map to Cemetery
                          </a>
                        </>
                      )}
                    </ObitInfoSection>
                  )}
                </ObitInfoRow>
                <ObitInfoRow>
                  {obituary.streamingLink && (
                    <ObitInfoSection>
                      <h3>Streaming service:</h3>
                      <a
                        target="_blank"
                        href={
                          obituary.streamingLink.substring(0, 4) == "http"
                            ? obituary.streamingLink
                            : "https://" + obituary.streamingLink
                        }
                      >
                        Link to Stream
                      </a>
                    </ObitInfoSection>
                  )}
                  {obituary.audioFileAddress && (
                    <ObitInfoSection>
                      <h3>Audio recording of service:</h3>
                      <a
                        target="_blank"
                        href={
                          obituary.audioFileAddress.substring(0, 4) == "http"
                            ? obituary.audioFileAddress
                            : "https://" + obituary.audioFileAddress
                        }
                      >
                        Download{" "}
                      </a>
                    </ObitInfoSection>
                  )}
                </ObitInfoRow>
                <ContactInfo>
                  If you have any questions, please call{" "}
                  <span>Goodman-Bensman Whitefish Bay Funeral Home. </span>
                  <PhoneSpan href="tel:(414) 964-3111">
                    (414) 964-3111
                  </PhoneSpan>
                </ContactInfo>
                <ButtonGroup>
                  <BottomButton
                    className="share"
                    onClick={() => setModalOpen(true)}
                  >
                    <img src={shareIcon} />
                    Share
                  </BottomButton>
                  <FacebookShareButton
                    url={`${process.env.REACT_APP_HOME}/obituaries/${obituary.id}`}
                    quote={"Obituary - " + obituary.name}
                  >
                    <BottomButton as="div" className="fb">
                      <img src={fbIcon} />
                      Facebook
                    </BottomButton>
                  </FacebookShareButton>
                  <EmailShareButton
                    url={`${process.env.REACT_APP_HOME}/obituaries/${obituary.id}`}
                    subject={`Obituary Notice - ${obituary.name}`}
                    body={`Goodman Bensman has posted an obituary for ${obituary.name}. To see the full obituary, please click the following link: `}
                  >
                    <BottomButton as="div" className="email">
                      <img src={mailIcon} />
                      Email
                    </BottomButton>
                  </EmailShareButton>
                  <BottomButton onClick={handlePrint}>
                    <img src={printIcon} />
                    Print
                  </BottomButton>
                </ButtonGroup>
              </>
            )}
            {activeView == 1 && (
              <GuestBookContainer>
                <h3>Postings for {obituary.name} </h3>
                {obituary.guestBook.posts
                  .slice(0, visualPosts)
                  .map((post, i) => {
                    if (post.approved)
                      return <GuestBookPost key={i} post={post} />;
                  })}
                {visualPosts < obituary.guestBook.posts.length && (
                  <SeeMoreLink onClick={seeMorePosts}>
                    See more postings
                  </SeeMoreLink>
                )}
                <SignForm>
                  <Dimmer active={postLoading}>
                    <Loader active={postLoading}>Submitting Post</Loader>
                  </Dimmer>
                  <h2>Sign the guestbook</h2>
                  {!(postData && postData.createPost.id) && (
                    <Form onSubmit={handleGuestbookSubmit}>
                      <Form.TextArea
                        required
                        value={text}
                        label="Write a post"
                        onChange={(e) => setText(e.target.value)}
                      />
                      <Form.Input
                        required
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Form.Input
                        required
                        value={email}
                        label="Email Address"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <SignButton type="submit">Submit</SignButton>
                      <FormCaption>
                        *Goodman-Bensman approves all guestbook entries before
                        they are posted.
                      </FormCaption>
                    </Form>
                  )}
                  {postData && postData.createPost.id && (
                    <div>
                      Thank you for your post. Goodman-Bensman approves all
                      guestbook entries before they are posted.
                    </div>
                  )}
                </SignForm>
              </GuestBookContainer>
            )}
            {activeView == 2 && (
              <>
                <ObitInfoRow>
                  <ObitInfoSection>
                    <h3>Service information:</h3>
                    {!obituary.service.isPrivate && (
                      <>
                        <div className="obit-type">
                          {" "}
                          {obituary.service.type}
                        </div>
                        {obituary.service.address && (
                          <Address>{obituary.service.address}</Address>
                        )}
                        {obituary.service.date && (
                          <div>
                            {moment
                              .tz(
                                parseInt(obituary.service.date),
                                "America/Chicago"
                              )
                              .format("M/D/YYYY h:mma")}
                          </div>
                        )}
                        {obituary.service.address && (
                          <a
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${obituary.service.address.replace(
                              " ",
                              "%20"
                            )}`}
                          >
                            Map to Service
                          </a>
                        )}
                      </>
                    )}
                    {obituary.service.isPrivate && <div>Private Service</div>}
                  </ObitInfoSection>
                  {obituary.cemeteryName && (
                    <ObitInfoSection>
                      <h3>Cemetery:</h3>
                      <div>{obituary.cemeteryName}</div>
                      {obituary.cemeteryAddress && (
                        <>
                          {" "}
                          <Address>{obituary.cemeteryAddress}</Address>
                          <a
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${obituary.cemeteryAddress.replace(
                              " ",
                              "%20"
                            )}`}
                          >
                            Map to Cemetery
                          </a>
                        </>
                      )}
                    </ObitInfoSection>
                  )}
                </ObitInfoRow>
                {(obituary.streamingLink || obituary.audioFileAddress) && (
                  <ObitInfoRow>
                    {obituary.streamingLink && (
                      <ObitInfoSection>
                        <h3>Streaming service:</h3>
                        <a
                          target="_blank"
                          href={
                            obituary.streamingLink.substring(0, 4) == "http"
                              ? obituary.streamingLink
                              : "https://" + obituary.streamingLink
                          }
                        >
                          Link to Stream
                        </a>
                      </ObitInfoSection>
                    )}
                    {obituary.audioFileAddress && (
                      <ObitInfoSection>
                        <h3>Audio recording of service:</h3>
                        <a
                          target="_blank"
                          href={
                            obituary.audioFileAddress.substring(0, 4) == "http"
                              ? obituary.audioFileAddress
                              : "https://" + obituary.audioFileAddress
                          }
                        >
                          Download{" "}
                        </a>
                      </ObitInfoSection>
                    )}
                  </ObitInfoRow>
                )}
                <ObitInfoRow>
                  {obituary.memorialContributions.length != 0 && (
                    <ObitInfoSection>
                      <h3>Memorial Contributions:</h3>
                      {obituary.memorialContributions
                        .slice()
                        .sort((a, b) => a.sort - b.sort)
                        .map((m, i) => (
                          <MemorialContribution key={i}>
                            {m.website && (
                              <a href={m.website} target="_blank">
                                {m.name}
                              </a>
                            )}
                            {!m.website && (
                              <div className="website-name-no-link">
                                {m.name}
                              </div>
                            )}
                            {m.address && <Address>{m.address}</Address>}
                          </MemorialContribution>
                        ))}
                    </ObitInfoSection>
                  )}
                  {obituary.shivas.length > 0 && (
                    <ObitInfoSection>
                      <h3>Shiva:</h3>
                      {[...obituary.shivas]
                        .sort((s1, s2) => {
                          if (s1.index < s2.index) return -1;
                          if (s1.index > s2.index) return 1;
                          return 0;
                        })
                        .map((s, i) => {
                          return (
                            <Shiva key={i}>
                              {s.locationName && <div>{s.locationName}</div>}
                              {s.address && <Address>{s.address}</Address>}
                              {s.start && (
                                <div>
                                  {moment
                                    .tz(parseInt(s.start), "America/Chicago")
                                    .format("M/D/YYYY h:mma")}
                                  {s.end
                                    ? ` - ${moment
                                        .tz(parseInt(s.end), "America/Chicago")
                                        .format("h:mma")}`
                                    : ""}{" "}
                                  CST
                                </div>
                              )}
                              {s.address && (
                                <a
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${s.address.replace(
                                    " ",
                                    "%20"
                                  )}`}
                                >
                                  Map to Shiva
                                </a>
                              )}
                              {s.link && (
                                <a
                                  href={s.link}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  Link to Zoom
                                </a>
                              )}
                              {i != obituary.shivas.length - 1 && <Divider />}
                            </Shiva>
                          );
                        })}
                    </ObitInfoSection>
                  )}
                </ObitInfoRow>
                <ContactInfo>
                  If you have any questions, please call{" "}
                  <span>Goodman-Bensman Whitefish Bay Funeral Home. </span>
                  <PhoneSpan href="tel:(414) 964-3111">
                    (414) 964-3111
                  </PhoneSpan>
                </ContactInfo>
                <ButtonGroup>
                  <BottomButton
                    className="share"
                    onClick={() => setModalOpen(true)}
                  >
                    <img src={shareIcon} />
                    Share
                  </BottomButton>
                  <FacebookShareButton
                    url={`${process.env.REACT_APP_HOME}/obituaries/${obituary.id}`}
                    quote={"Obituary - " + obituary.name}
                  >
                    <BottomButton as="div" className="fb">
                      <img src={fbIcon} />
                      Facebook
                    </BottomButton>
                  </FacebookShareButton>
                  <EmailShareButton
                    url={`${process.env.REACT_APP_HOME}/obituaries/${obituary.id}`}
                    subject={`Obituary Notice - ${obituary.name}`}
                    body={`Goodman Bensman has posted an obituary for ${obituary.name}. To see the full obituary, please click the following link: `}
                  >
                    <BottomButton as="div" className="email">
                      <img src={mailIcon} />
                      Email
                    </BottomButton>
                  </EmailShareButton>
                  <BottomButton onClick={handlePrint}>
                    <img src={printIcon} />
                    Print
                  </BottomButton>
                </ButtonGroup>
              </>
            )}
          </ContentContainer>
        </div>
      )}
    </>
  );
};

export default Obituary;
