import React, { useState, useEffect } from "react";
import {
  Banner,
  colors,
  fonts,
  StandardButton,
  sizes,
} from "../styles/reusableStyles";
import obituariesBannerImg from "../media/obituaries.png";
import styled from "styled-components";
import { Input } from "semantic-ui-react";
import StayConnectedCTA from "./StayConnectedCTA";
import { useQuery } from "@apollo/client";
import queries from "../helpers/queries";
import obituaryLeaf from "../media/obituaryLeaf.png";
import obitMenorah from "../media/obit-menorah.png";
import obitFlower from "../media/obit-flower.png";
import obitSky from "../media/obit-sky.png";
import obitWater from "../media/obit-water.png";
import moment from "moment";
// import { useHistory } from "react-router";

const ObituariesBanner = styled(Banner)`
  background: ${({ img }) =>
    `linear-gradient(0deg, rgba(73, 77, 73, 0.2), rgba(73, 77, 73, 0.2)), url(${img})`};
  margin-bottom: 0;
  background-size: cover;

  h1 {
    color: white;
  }
`;

const SearchSection = styled.div`
  background: ${colors.offWhite};
  padding: 24px 16px;
  text-align: center;
  margin-bottom: 24px;
  h2 {
    color: ${colors.primaryGreen};
    line-height: 40px;
    height: 40px;
    margin-bottom: 8px;
  }
`;
const CTA = styled.div`
  margin: 16px;
  box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);
  border-radius: 12px;
  @media screen and (min-width: ${sizes.tablet}) {
    display: none;
  }
`;

const ObituarySearchInput = styled(Input)`
  &.ui.input {
    /* display: block; */
    height: 48px;
    display: flex;
    @media screen and (min-width: ${sizes.max}) {
      margin-bottom: 0px;
    }
  }
  input {
    /* width: 100%; */
    height: 100%;
    border-color: ${colors.offWhite} !important;
  }
  button {
    width: 56px;
    background: ${colors.primaryGreen} !important;
    display: flex;
    justify-content: center;
    i::before {
      font-size: 18px;
    }
  }
  @media screen and (min-width: ${sizes.tablet}) {
    width: 80%;
    max-width: 610px;
    margin: 0 auto 48px auto;
  }
`;
const ObituaryCardsContainer = styled.div`
  @media screen and (min-width: ${sizes.max}) {
    max-width: 950px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
`;

const ObituaryCardStyle = styled.div`
  margin: 0 16px 24px 16px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.3);

  @media screen and (min-width: ${sizes.tablet}) {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto 24px auto;
  }

  @media screen and (min-width: ${sizes.max}) {
    flex: 1 0 29%;
    max-width: 29%;
    margin: 0 16px 24px 16px;
  }
`;

const ObituaryImage = styled.img`
  border-radius: 12px 12px 0 0;
  width: 100%;
  height: 320px;
  @media screen and (min-width: ${sizes.max}) {
    height: 200px;
  }
  object-fit: cover;
`;

const ObituaryContent = styled.div`
  border-radius: 0 0 12px 12px;
  padding: 16px;
  background: white;
  @media screen and (min-width: ${sizes.tablet}) {
    flex: 1;
  }

  h3 {
    color: ${colors.primaryGreen};
    margin-bottom: 4px;
  }
`;

const Caption = styled.div`
  ${fonts.caption}
  color: ${colors.greyText};
  margin-bottom: 4px;
`;

const Quote = styled.div`
  ${fonts.quote}
  color: ${colors.smallGreyText};
  margin-bottom: 16px;

  @media screen and (min-width: ${sizes.max}) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const ReadMoreLink = styled.a`
  cursor: pointer;
  display: block;
  text-align: right;
  color: ${colors.secondaryOrange};
  text-decoration: underline;
  &:hover {
    color: ${colors.secondaryOrange};
    text-decoration: underline;
  }
`;

const LoadMoreButton = styled(StandardButton)`
  background-color: white;
  border-color: ${colors.primaryGreen};
  color: ${colors.primaryGreen};
  display: block;
  margin: auto;
  margin-bottom: 27px;
`;

const ObituaryCard = ({ obituary }) => {
  // const history = useHistory();

  const getObitImage = () => {
    if (obituary.imageAddress) return obituary.imageAddress;
    if (obituary.useMenorahImage) return obitMenorah;
    switch (obituary.id.charCodeAt(24) % 4) {
      case 0:
        return obituaryLeaf;
      case 1:
        return obitFlower;
      case 2:
        return obitSky;
      case 3:
        return obitWater;
      default:
        return obituaryLeaf;
    }
  };
  const obitImage = getObitImage();
  return (
    <ObituaryCardStyle>
      <ObituaryImage src={obitImage} />
      <ObituaryContent>
        <h3> {obituary.name}</h3>
        <Caption>
          {moment(parseInt(obituary.deathDate))
            .utcOffset(0)
            .format("MMMM Do, YYYY")}
        </Caption>
        <Quote>{obituary.excerpt ? obituary.excerpt : ""}</Quote>
        <ReadMoreLink
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_HOME}/obituaries/${obituary.id}`}
        >
          Read More
        </ReadMoreLink>
      </ObituaryContent>
    </ObituaryCardStyle>
  );
};
const Obituaries = () => {
  const [viewNum, setViewNum] = useState(12);
  const [search, setSearch] = useState("");
  const [fullyFetched, setFullyFetched] = useState(false);
  const { data, fetchMore } = useQuery(queries.getObituaries, {
    variables: {
      offset: 0,
      limit: 24,
    },
    fetchPolicy: "network-only",
  });
  const obituaries = data
    ? data.obituaries.filter(
        (o) => o.name.toLowerCase().includes(search.toLowerCase()) && o.visible
      )
    : [];
  const obituariesToShow =
    obituaries.length < viewNum ? obituaries : obituaries.slice(0, viewNum);

  useEffect(() => {
    if (search && !fullyFetched) {
      setFullyFetched(true);

      fetchMore({
        variables: {
          offset: undefined,
          limit: undefined,
        },
      });
    }
  }, [search, fetchMore, fullyFetched]);

  return (
    <>
      <ObituariesBanner img={obituariesBannerImg}>
        <h1>Obituaries</h1>
      </ObituariesBanner>
      <SearchSection>
        <h2>Search Obituaries</h2>
        <ObituarySearchInput
          onChange={(_, { value }) => setSearch(value)}
          placeholder="Search by first or last name"
          value={search}
          action={{
            color: "green",
            icon: "search",
            onClick: () => {
              console.log(search);
            },
          }}
        />
      </SearchSection>
      <CTA>
        <StayConnectedCTA />
      </CTA>
      <ObituaryCardsContainer>
        {obituariesToShow.map((o, i) => (
          <ObituaryCard key={i} obituary={o} />
        ))}
      </ObituaryCardsContainer>
      {obituaries.length >= viewNum && (
        <LoadMoreButton
          onClick={() => {
            if (obituaries.length - viewNum < 12)
              fetchMore({
                variables: {
                  offset: data.obituaries.length,
                  limit: 24,
                },
              });
            setViewNum(viewNum + 12);
          }}
        >
          Load More
        </LoadMoreButton>
      )}
    </>
  );
};
export default Obituaries;
