import React from 'react';
import {
	ModalContainer,
	ModalTitle,
	StandardButton,
	colors,
	sizes,
} from '../styles/reusableStyles';
import mailIcon from '../media/mailIcon.png';
import fbIcon from '../media/fbIcon.png';
import styled from 'styled-components';
import { FacebookShareButton, EmailShareButton } from 'react-share';

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	min-height: 160px;
`;

const BottomButton = styled(StandardButton)`
	&.fb {
		display: none;
	}
	&.email {
		display: none;
	}
	background-color: ${colors.primaryGreen};
	color: white;
	border-color: ${colors.primaryGreen};
	min-width: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		height: 21px;
		margin-right: 5px;
	}
	@media screen and (min-width: ${sizes.tablet}) {
		max-width: 192px;
		&.fb {
			display: flex;
		}
		&.email {
			display: flex;
		}
		&.share {
			display: none;
		}
	}
`;

const ShareButton = styled(BottomButton)`
	width: 180px;
`;

const ObituaryShareModal = ({ obit }) => {
	return (
		<ModalContainer>
			<ModalTitle>Share Obituary</ModalTitle>
			<ButtonContainer>
				<FacebookShareButton
					url={`${process.env.REACT_APP_HOME}/obituaries/${obit.id}`}
					quote={'Obituary - ' + obit.name}
				>
					<ShareButton as='div'>
						<img src={fbIcon} />
						Facebook
					</ShareButton>
				</FacebookShareButton>
				<EmailShareButton
					url={`${process.env.REACT_APP_HOME}/obituaries/${obit.id}`}
					subject={`Obituary Notice - ${obit.name}`}
					body={`Goodman Bensman has posted an obituary for ${obit.name}. To see the full obituary, please click the following link: `}
				>
					<ShareButton as='div'>
						<img src={mailIcon} />
						Email
					</ShareButton>
				</EmailShareButton>
			</ButtonContainer>
		</ModalContainer>
	);
};

export default ObituaryShareModal;
