import { gql } from "@apollo/client";

const queries = {
  menuOpen: gql`
    {
      menuOpen @client
    }
  `,
  checkPassword: gql`
    query CheckPassword($password: String!) {
      checkPassword(password: $password)
    }
  `,
  getPosts: gql`
    query GetPosts {
      posts {
        id
        name
        email
        text
        dateSubmitted
        approved
        denied
        guestBook {
          obituary {
            name
          }
        }
      }
    }
  `,
  getObituaries: gql`
    query GetObituaries($offset: Int, $limit: Int) {
      obituaries(offset: $offset, limit: $limit) {
        id
        name
        deathDate
        excerpt
        imageAddress
        useMenorahImage
        visible
      }
    }
  `,
  getObituariesList: gql`
    query GetObituariesList {
      obituaries {
        id
        name
        deathDate
      }
    }
  `,
  getObituaryById: gql`
    query GetObituaryById($id: ID!) {
      getObituaryById(id: $id) {
        id
        name
        birthDate
        deathDate
        excerpt
        description
        cemeteryName
        cemeteryAddress
        streamingLink
        imageAddress
        audioFileAddress
        useMenorahImage
        visible
        service {
          id
          type
          address
          date
          isPrivate
        }
        memorialContributions {
          id
          name
          address
          website
          sort
        }
        shivas {
          id
          start
          end
          address
          locationName
          link
          index
        }
        guestBook {
          id
          posts {
            id
            name
            email
            text
            dateSubmitted
            approved
            denied
          }
        }
      }
    }
  `,
};

export default queries;
