import React from 'react';
import {
	ModalContainer,
	ModalTitle,
	ModalBody,
} from '../styles/reusableStyles';

const BannerModal = () => {
	return (
		<ModalContainer>
			<ModalTitle>Health and Safety</ModalTitle>
			<ModalBody>
				To protect everyone's health and safety, we have modified our
				services to include a limited number of mourners. We urge
				mourners to adhere to CDC recommendations such as face masks,
				social distancing, hand washing and self-quarantine. <br />
				<br />
				Losing a loved one is especially difficult without a traditional
				funeral service. We encourage everyone to reach out to mourners
				personally to express condolences and to provide much needed
				personal contact. <br />
				<br />
				As always, we offer phone and e-mail arrangements. Families need
				not physically visit the funeral home. Please write or call with
				questions. We are here to help.
			</ModalBody>
		</ModalContainer>
	);
};

export default BannerModal;
