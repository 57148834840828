import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalStyles } from "./styles/globalStyles";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import "semantic-ui-less/semantic.less";
import ReactGA from "react-ga";
import { offsetLimitPagination } from "@apollo/client/utilities";

const trackingId = "UA-181863461-1";
ReactGA.initialize(trackingId);

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_SERVER_ENDPOINT}/graphql`,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          obituaries: offsetLimitPagination(),
        },
      },
    },
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
