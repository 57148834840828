import React from 'react';
import styled from 'styled-components';
import {
	Banner,
	HeroDesc,
	colors,
	StandardButton,
	sizes,
} from '../styles/reusableStyles';
import outside2 from '../media/ServicesHero.png';
import preplanningService from '../media/preplanningService.png';
import monuments from '../media/monuments.png';
import griefSupport from '../media/grief.png';
import serviceOptions1 from '../media/serviceOptions1.png';
import { useHistory } from 'react-router';

const ServicesBanner = styled(Banner)`
	/* background: ${({ img }) =>
		`linear-gradient(0deg, rgba(73, 77, 73, 0.2), rgba(73, 77, 73, 0.2)), url(${img})`}; */
	mix-blend-mode: darken;
	h1 {
		color: white;
	}
	/* background-position: 0% 25%; */
	background-position: 0 100%;
	background-repeat: no-repeat;
`;

const ServicesCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 24px;
	@media screen and (min-width: ${sizes.tablet}) {
		margin: 0 40px;
		margin-bottom: 48px;
	}
	margin-bottom: 48px;

	h2 {
		color: ${colors.primaryGreen};
		margin-bottom: 8px;
		@media screen and (min-width: ${sizes.tablet}) {
			font-size: 32px;
			line-height: 44px;
		}
	}

	img {
		width: 100%;
		border-radius: 12px;
		max-width: 772px;
		max-height: 360px;
		object-fit: cover;
		cursor: pointer;
		&.monuments {
			object-position: 0 100%;
		}
	}
`;

const ServicesButton = styled(StandardButton)`
	max-width: 152px;
	margin-top: -24px;
	background-color: ${colors.primaryGreen};
	border-color: ${colors.primaryGreen};
`;

const Services = () => {
	const history = useHistory();
	return (
		<div>
			<ServicesBanner img={outside2}>
				<h1>Services</h1>
			</ServicesBanner>
			<HeroDesc>
				Our mission is to help our families honor their loved one with
				the highest level of compassion, dignity and respect.
			</HeroDesc>
			<ServicesCard>
				<h2>Service Options</h2>
				<img
					src={serviceOptions1}
					onClick={() => history.push('/services/options')}
				/>
				<ServicesButton
					onClick={() => history.push('/services/options')}
				>
					Learn More
				</ServicesButton>
			</ServicesCard>
			<ServicesCard>
				<h2>Preplanning</h2>
				<img
					src={preplanningService}
					onClick={() => history.push('/services/preplanning')}
				/>
				<ServicesButton
					onClick={() => history.push('/services/preplanning')}
				>
					Learn More
				</ServicesButton>
			</ServicesCard>
			<ServicesCard>
				<h2>Monuments</h2>
				<img
					className='monuments'
					src={monuments}
					onClick={() => history.push('/services/monuments')}
				/>
				<ServicesButton
					onClick={() => history.push('/services/monuments')}
				>
					Learn More
				</ServicesButton>
			</ServicesCard>
			<ServicesCard>
				<h2>Grief Support</h2>
				<img
					src={griefSupport}
					onClick={() => history.push('/services/grief_support')}
				/>
				<ServicesButton
					onClick={() => history.push('/services/grief_support')}
				>
					Learn More
				</ServicesButton>
			</ServicesCard>
		</div>
	);
};

export default Services;
