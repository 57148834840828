import React from 'react';
import styled from 'styled-components';
import logo from '../media/GBBanner.png';
import desktopBanner from '../media/desktopBanner.png';
import menu from '../media/menu.svg';
import close from '../media/close.svg';
import { sizes, fonts, colors } from '../styles/reusableStyles';
import { NavLink, useHistory } from 'react-router-dom';
import orangePhone from '../media/orangePhone.png';
import { Dropdown } from 'semantic-ui-react';

const NavContainer = styled.div`
	height: 72px;
	position: fixed;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0px 20px;
	z-index: 100;

	@media screen and (min-width: ${sizes.navMin}) {
		height: 96px;
		max-width: 1024px;
		padding: 0px 48px;
		left: 50%;
		transform: translateX(-50%);
	}
`;

const FlexBalance = styled.div`
	flex: 1;
	@media screen and (min-width: ${sizes.navMin}) {
		display: none;
	}
`;

const LogoImage = styled.img`
	height: 72px;
	cursor: pointer;
	@media screen and (min-width: ${sizes.navMin}) {
		display: none;
	}
`;

const DesktopBanner = styled.img`
	display: none;
	@media screen and (min-width: ${sizes.navMin}) {
		display: block;
	}
	height: 40px;
	cursor: pointer;
`;

const MenuTrigger = styled.div`
	@media screen and (min-width: ${sizes.navMin}) {
		display: none;
	}
	flex: 1;
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: flex-end;

	img {
		cursor: pointer;
		width: 24px;
	}
`;

// const Menu = styled.div`
// 	position: fixed;
// 	top: 72px;
// 	right: 0px;
// 	width: 240px;
// 	display: ${({ open }) => (open ? 'block' : 'none')};
// 	height: 100%;
// 	background-color: red;
// `;

const NavLinks = styled.div`
	display: none;
	@media screen and (min-width: ${sizes.navMin}) {
		display: flex;
	}
	flex: 1;
	justify-content: space-evenly;
`;
const NavGroup = styled.div``;
const NavCTA = styled.div`
	cursor: pointer;
	display: none !important;
	@media screen and (min-width: ${sizes.navMin}) {
		display: block !important;
	}
	&:hover{
		text-decoration: underline;
	}
	${fonts.desktopCaption}
	color: ${colors.primaryOrange};
	font-weight: bold;
	width: 160px;
	text-align: center;
	span{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	img{
		height: 18px;
		margin-right: 8px;
	}
	
`;
const NavLinkCustom = styled(NavLink)`
	${fonts.bigBody}
	color: ${colors.smallGreyText};
`;

const Nav = ({ toggleMenu, menuOpen }) => {
	const history = useHistory();
	return (
		<NavContainer>
			{/* Desktop */}
			<DesktopBanner
				alt='Goodman Bensman Funeral Home'
				src={desktopBanner}
				onClick={() => history.push('/')}
			/>
			<NavLinks>
				<NavGroup>
					<NavLinkCustom to='/about'>About</NavLinkCustom>
					<Dropdown basic pointing='top right'>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => history.push('/about/staff')}
								text='Staff'
							/>
							<Dropdown.Divider />
							<Dropdown.Item
								onClick={() => history.push('/about/facility')}
								text='Facility'
							/>
						</Dropdown.Menu>
					</Dropdown>
				</NavGroup>
				<NavGroup>
					<NavLinkCustom to='/services'>Services</NavLinkCustom>
					<Dropdown basic pointing='top right'>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() =>
									history.push('/services/options')
								}
								text='Service Options'
							/>
							<Dropdown.Divider />
							<Dropdown.Item
								onClick={() =>
									history.push('/services/preplanning')
								}
								text='Preplanning'
							/>
							<Dropdown.Divider />
							<Dropdown.Item
								onClick={() =>
									history.push('/services/monuments')
								}
								text='Monuments'
							/>
							<Dropdown.Divider />
							<Dropdown.Item
								onClick={() =>
									history.push('/services/grief_support')
								}
								text='Grief Support'
							/>
						</Dropdown.Menu>
					</Dropdown>
				</NavGroup>
				<NavGroup>
					<NavLinkCustom to='/obituaries'>Obituaries</NavLinkCustom>
				</NavGroup>
				<NavGroup>
					<NavLinkCustom to='/contact'>Contact Us</NavLinkCustom>
				</NavGroup>
			</NavLinks>
			<NavCTA onClick={() => history.push('/immediate_need')}>
				<div>
					Available 24/7 for immediate support
					<br />
					<span>
						<img src={orangePhone} />
						(414) 964-3111
					</span>
				</div>
			</NavCTA>
			{/* Mobile */}
			<FlexBalance />
			<LogoImage
				onClick={() => history.push('/')}
				alt='Goodman Bensman Funeral Home'
				src={logo}
			/>
			<MenuTrigger>
				<img
					onClick={toggleMenu}
					alt={menuOpen ? 'close navigation' : 'open navigation'}
					src={menuOpen ? close : menu}
				/>
			</MenuTrigger>
		</NavContainer>
	);
};

export default Nav;
