import React, { useState } from "react";
import styled from "styled-components";
import {
  Banner,
  HeroDesc,
  colors,
  StandardButton,
  fonts,
  CTACard,
  GreenButton,
  ModalContainer,
  ModalBody,
  ModalTitle,
  sizes,
} from "../styles/reusableStyles";
import griefGeneral from "../media/griefGeneral.png";
import griefKids from "../media/griefKids.png";
import griefCounseling from "../media/griefCounseling.png";
import griefSupport from "../media/griefx3.png";
import { useHistory, Switch, Route } from "react-router";
import books from "../media/books.png";
import parentBook from "../media/parentBook.png";
import parentBooks2 from "../media/parentBooks2.png";
import teensBook from "../media/teensBook.png";
import childrenBook from "../media/childrenBook.png";
import silvia from "../media/silvia.png";
import { Modal } from "semantic-ui-react";

const ServicesBanner = styled(Banner)`
  background: ${({ img }) =>
    `linear-gradient(0deg, rgba(73, 77, 73, 0.2), rgba(73, 77, 73, 0.2)), url(${img})`};
  mix-blend-mode: darken;
  background-size: cover;
  background-position: 0 45%;
  h1 {
    color: white;
  }
`;

const ServicesCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;
  margin-bottom: 48px;

  h2 {
    color: ${colors.primaryGreen};
  }

  img {
    cursor: pointer;
    width: 100%;
    border-radius: 12px;
    max-width: 772px;
    max-height: 360px;
    object-fit: cover;
  }
`;

const ServicesButton = styled(StandardButton)`
  max-width: 152px;
  margin-top: -24px;
  background-color: ${colors.primaryGreen};
  border-color: ${colors.primaryGreen};
`;

const PhoneSpan = styled.span`
  cursor: pointer;
  color: ${colors.secondaryOrange};
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    color: ${colors.secondaryOrange};
    text-decoration: underline;
  }
`;

const GriefSection = styled.div`
  h2 {
    text-align: center;
    color: ${colors.primaryGreen};
    margin-bottom: 24px;
  }
`;

const ViewToggle = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-evenly;
  div {
    cursor: pointer;
    flex: 1;
    ${fonts.bigBody}
    color: ${colors.primaryGreen};
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    &.active {
      border-bottom: solid 2px ${colors.primaryGreen};
    }
    text-transform: uppercase;
    text-align: center;
  }
  @media screen and (min-width: ${sizes.tablet}) {
    width: 80%;
    max-width: 520px;
    margin: 0 auto;
  }
`;

const OtherResources = styled.div`
  text-align: center;
  margin-bottom: 24px;
  h2 {
    color: ${colors.primaryGreen};
  }

  a {
    cursor: pointer;
    color: ${colors.secondaryOrange};
    text-decoration: underline;
    ${fonts.bigBody}
    font-weight: bold;
    display: block;
    margin-bottom: 16px;

    &:hover {
      color: ${colors.secondaryOrange};
      text-decoration: underline;
    }
  }
`;

const OtherResourceLinks = styled.div`
  @media screen and (min-width: ${sizes.tablet}) {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    max-width: 720px;
    margin: auto;
  }
`;

const ResourceSection = styled.div`
  background-color: white;
  padding: 21px 0px;
  margin-bottom: 24px;
  ul {
    a {
      color: ${colors.secondaryOrange};
      text-decoration: underline;
      ${fonts.bigBody}
      display: block;
      margin-bottom: 8px;

      &:hover {
        color: ${colors.secondaryOrange};
        text-decoration: underline;
      }
    }
    div {
      color: #272927;
      margin-bottom: 24px;
      ${fonts.bigBody}
    }
  }
  h3 {
    padding: 0 16px;
    color: ${colors.greyText};
    margin-bottom: 24px;
  }
  h2 {
    color: ${colors.smallGreyText};
    margin-bottom: 24px;
    text-align: center;
  }
  @media screen and (min-width: ${sizes.tablet}) {
    width: 80%;
    max-width: 772px;
    margin: 0 auto 40px auto;
  }
`;

const BookImage = styled.img`
  filter: drop-shadow(0px 4px 10px rgba(128, 128, 128, 0.3));
  border-radius: 12px;
  max-width: 100%;
  @media screen and (min-width: ${sizes.tablet}) {
    display: block;
    margin: auto;
  }
`;

const Phone = styled.a`
  font-weight: bold !important;
`;

const Location = styled.span`
  font-weight: bold !important;
`;

const Silvia = styled.img`
  margin: 24px;
  margin-top: 0px;
  max-width: calc(100% - 48px);
  @media screen and (min-width: ${sizes.tablet}) {
    display: block;
    margin: 0 auto 32px auto;
  }
`;

const SilviaInfo = styled.div`
  text-align: center;
  margin: 16px;
  margin-top: 0px;
  h3 {
    color: ${colors.primaryGreen};
    margin-bottom: 8px;
  }
`;
const Qualifications = styled.div`
  ${fonts.quote}
  margin: auto;
  margin-bottom: 16px;
  max-width: 300px;
`;

const ContactSilvia = styled.div`
  margin: 16px;
  margin-top: 24px;
  ${fonts.bigBody}
  color: ${colors.smallGreyText};
  a {
    color: ${colors.secondaryOrange};
    text-decoration: underline;
    ${fonts.bigBody}

    &:hover {
      color: ${colors.secondaryOrange};
      text-decoration: underline;
    }
  }
`;

const AboutSilviaModal = () => {
  return (
    <ModalContainer>
      <ModalTitle>About Silvia</ModalTitle>
      <ModalBody>
        <bold>Silvia Gale</bold> is a Certified Life Celebrant and
        Double-Certified Grief Recovery Specialist working in the greater
        Milwaukee area.
        <br />
        <br /> She received her Certifications through the highly esteemed Grief
        Recovery Institute headquartered in Bend, Oregon, and through the
        University of Wisconsin-Madison Behavioral Health Continuing Studies
        Program.
        <br />
        <br />
        Silvia is the founder of a local support group called, “Parting
        Thoughts: Conversations on Living Life After Loss” and is committed to
        helping those in grief find healing, hope, purpose and meaning again
        after their loss.
      </ModalBody>
    </ModalContainer>
  );
};

const GriefSupportCounseling = () => {
  const history = useHistory();
  const [activeView, setActiveView] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Modal
        closeIcon
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={<AboutSilviaModal />}
      />
      <h2>Grief Counseling</h2>
      <ViewToggle>
        <div
          className={activeView == 0 ? "active" : ""}
          onClick={() => setActiveView(0)}
        >
          Organizations
        </div>
        <div
          className={activeView == 1 ? "active" : ""}
          onClick={() => setActiveView(1)}
        >
          Grief Therapists
        </div>
      </ViewToggle>
      <ResourceSection>
        {activeView == 0 && (
          <>
            <ul>
              <li>
                <a
                  target="_blank"
                  href=" https://childrenswi.org/patients-and-families/milwaukee-campus/inpatient-visit/during-your-stay/bereavement-program"
                >
                  Children's Hospital of Wisconsin
                </a>
                <div>
                  <Location>Bereavement &#38; family services</Location>
                  <br />
                  9000 W. Wisconsin Ave.
                  <br />
                  Wauwatosa, WI 53201
                  <br />
                  <Phone href="tel:(414) 266-2995">(414) 266-2995</Phone>
                </div>
              </li>
              <li>
                <a
                  href="https://www.horizonhomecareandhospice.org/grief-resources/"
                  target="_blank"
                >
                  Horizon Grief Resource Center
                </a>
                <div>
                  <Location>Grief counseling center</Location>
                  <br />
                  11400 W. Lake Park Drive
                  <br />
                  Milwaukee, WI 53224
                  <br />
                  <Phone href="tel:(414) 586-8383">(414) 586-8383</Phone>
                </div>
              </li>
            </ul>
          </>
        )}
        {activeView == 1 && (
          <>
            <h2>Meet Silvia</h2>
            <Silvia src={silvia} />
            <SilviaInfo>
              <h3>Silvia Gale</h3>
              <Qualifications>
                Certified Life Celebrant,
                <br />
                Double-Certified Grief Recovery Specialist
              </Qualifications>
              <GreenButton onClick={() => setModalOpen(true)}>
                About Silvia
              </GreenButton>
              <ContactSilvia>
                To contact Silvia, please email her at <br />
                <a href="mailto:partingthoughts@outlook.com">
                  PartingThoughts@outlook.com
                </a>
                <br />
                or
                <br />
                call <a href="tel:(414) 366-4908">(414) 366-4908</a>
              </ContactSilvia>
            </SilviaInfo>
          </>
        )}
      </ResourceSection>
      <OtherResources>
        <h2>Other Resources</h2>
        <OtherResourceLinks>
          <a onClick={() => history.push("/services/grief_support/general")}>
            General Grief Resources
          </a>
          <a onClick={() => history.push("/services/grief_support/children")}>
            Families / Teens / Children
          </a>
        </OtherResourceLinks>
      </OtherResources>
    </>
  );
};

const GriefSupportKids = () => {
  const history = useHistory();
  const [activeView, setActiveView] = useState(0);
  return (
    <>
      <h2>Families / Teens / Children Resources</h2>
      <ViewToggle>
        <div
          className={activeView == 0 ? "active" : ""}
          onClick={() => setActiveView(0)}
        >
          Parents
        </div>
        <div
          className={activeView == 1 ? "active" : ""}
          onClick={() => setActiveView(1)}
        >
          Teens
        </div>
        <div
          className={activeView == 2 ? "active" : ""}
          onClick={() => setActiveView(2)}
        >
          Children
        </div>
      </ViewToggle>
      <ResourceSection>
        {activeView == 0 && (
          <>
            <h3>Online Resources for Parents</h3>
            <ul>
              <li>
                <a target="_blank" href="https://www.compassionatefriends.org/">
                  Compassionate Friends
                </a>
                <div>
                  Offers comfort, hope and support to every family experiencing
                  the death of a son or a daughter; a brother or a sister; or a
                  grandchild and helps others better assist the grieving family.
                </div>
              </li>
              <li>
                <a target="_blank" href="https://www.bereavedparentsusa.org/">
                  Bereaved Parents of the USA
                </a>
                <div>
                  Offers support, understanding, compassion and hope to bereaved
                  parents, grandparents or siblings struggling to rebuild their
                  lives after the death of their children, grandchildren or
                  siblings.
                </div>
              </li>
              <li>
                <a target="_blank" href="https://stillstandingmag.com/">
                  Still Standing Magazine
                </a>
                <div>
                  Helps to embrace life for everything that it is after
                  experiencing the loss of a child or infertility.
                </div>
              </li>
            </ul>
            <BookImage src={parentBooks2} />
            <h3>Grief Books for Parents</h3>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Healing-Parents-Grieving-Heart-Practical-ebook/dp/B0087GZ6IU"
                >
                  Healing A Parent's Grieving Heart
                </a>
                <div>by Alan Wolfelt, Ph.D.</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Permission-Thrive-Journey-Grief-Growth-ebook/dp/B07LDZK645"
                >
                  Permission to Thrive: My Journey from Grief to Growth
                </a>
                <div>by Susan Angel Miller</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Shattered-Surviving-Loss-Child-Grief-ebook/dp/B01MTBU28W"
                >
                  Shattered: Surviving the Loss of a Child
                </a>
                <div>by Gary Roe</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Its-That-Youre-Not-Understand/dp/1622039076"
                >
                  It’s OK That You’re Not OK
                </a>
                <div>by Megan Devine</div>
              </li>
            </ul>
            <BookImage src={parentBook} />
            <h3>Grief Book for Families</h3>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Tear-Soup-Recipe-Healing-After/dp/0961519762"
                >
                  Tear Soup: A Recipe for Healing After Loss
                </a>
                <div>by Pat Schwiebert and Chuck DeKlyen</div>
              </li>
            </ul>
          </>
        )}
        {activeView == 1 && (
          <>
            <h3>Online Resources for Teens</h3>
            <ul>
              <li>
                <a target="_blank" href="https://slapd.com/">
                  Slap'd
                </a>
                <div>Social media for teens who have lost a parent.</div>
              </li>
              <li>
                <a target="_blank" href="http://toodamnyoung.com/">
                  Too Damn Young
                </a>
                <div>Community and information for grieving teens.</div>
              </li>
              <li>
                <a target="_blank" href="http://sharedgrief.org/">
                  Shared Grief Project
                </a>
                <div>
                  For bereaved children and teens. Shared stories of individuals
                  who have experienced a major death at an early age and have
                  gone on to live healthy, happy and successful lives.
                </div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.dougy.org/grief-resources/developmental-grief-responses/"
                >
                  The Dougy Center
                </a>
                <div>
                  A safe place for grieving children, teens, young adults and
                  their families to share their experiences. Media for teens who
                  have lost a parent.
                </div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.compassionatefriends.org/find-support/family-support/"
                >
                  The Compassionate Friends for Siblings
                </a>
                <div>A closed Facebook group for bereaved siblings.</div>
              </li>
            </ul>
            <BookImage src={teensBook} />
            <h3>Grief Books for Teens</h3>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.freespirit.com/counseling-and-social-emotional-learning/when-a-friend-dies-updated-3rd-edition-marilyn-gootman#:~:text=When%20a%20Friend%20Dies%3A%20A,9781631984228%20%7C%20Books%20%7C%20Free%20Spirit%20Publishing"
                >
                  When a Friend Dies: A Book for Teens about Grieving and
                  Healing
                </a>
                <div>by Marilyn E. Gootman, Ed.D.</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/When-Death-Walks-Mark-Scrivani/dp/156123012X"
                >
                  When Death Walks In: For Teenagers Facing Grief
                </a>
                <div>by Mark Scrivani</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Fire-Heart-Ice-Veins-Experiencing/dp/1561230561"
                >
                  Fire in My Heart, Ice in My Veins: A Journal for Teenagers
                </a>
                <div>by Enid S. Traisman</div>
              </li>
            </ul>
          </>
        )}
        {activeView == 2 && (
          <>
            <h3>Online Resources for Children</h3>
            <ul>
              <li>
                <a target="_blank" href="http://sharedgrief.org/">
                  Shared Grief Project
                </a>
                <div>
                  For bereaved children and teens. Shared stories of individuals
                  who have experienced a major death at an early age and have
                  gone on to live healthy, happy and successful lives.
                </div>
              </li>
              <li>
                <a
                  target="_blank"
                  href=" https://www.dougy.org/grief-resources/developmental-grief-responses/"
                >
                  The Dougy Center
                </a>
                <div>
                  A safe place for grieving children, teens, young adults and
                  their families to share their experiences. Media for teens who
                  have lost a parent.
                </div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.compassionatefriends.org/find-support/family-support/"
                >
                  The Compassionate Friends for Siblings
                </a>
                <div>A closed Facebook group for bereaved siblings.</div>
              </li>
            </ul>
            <BookImage src={childrenBook} />
            <h3>Grief Books for Children</h3>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Healing-Your-Grieving-Heart-Kids/dp/1879651270"
                >
                  Healing Your Grieving Heart for Kids
                </a>
                <div>by Alan Wolfelt, Ph.D.</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Lifetimes-Beautiful-Explain-Death-Children/dp/0553344021"
                >
                  Lifetimes: The Beautiful Way to Explain Death to Children
                </a>
                <div>
                  (For elementary age and toddlers) <br />
                  by Brian Mellonie and Robert Ingpen{" "}
                </div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Invisible-String-Patrice-Karst/dp/031648623X"
                >
                  The Invisible String
                </a>
                <div>
                  (For elementary age) <br />
                  by Patrice Karst
                </div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/What-Earth-When-Someone-Dies/dp/1575420554#:~:text=Author%20Trevor%20Romain%20offers%20practical,questions%20kids%20have%20about%20death"
                >
                  What on Earth Do You Do When Someone Dies?
                </a>
                <div>by Trevor Romain</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Something-Very-Sad-Happened-Understanding/dp/1433822660"
                >
                  Something Very Sad Happened: A Toddler's Guide to
                  Understanding Death
                </a>
                <div>by Bonnie Zucker</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Tear-Soup-Recipe-Healing-After/dp/0961519762"
                >
                  Tear Soup: A Recipe for Healing After Loss
                </a>
                <div>by Pat Schwiebert and Chuck DeKlyen</div>
              </li>
            </ul>
          </>
        )}
      </ResourceSection>
      <OtherResources>
        <h2>Other Resources</h2>
        <OtherResourceLinks>
          <a onClick={() => history.push("/services/grief_support/general")}>
            General Grief Resources
          </a>
          <a onClick={() => history.push("/services/grief_support/counseling")}>
            Grief Counseling
          </a>
        </OtherResourceLinks>
      </OtherResources>
    </>
  );
};

const GriefSupportGeneral = () => {
  const history = useHistory();
  const [activeView, setActiveView] = useState(0);
  return (
    <>
      <h2>General Grief Resources</h2>
      <ViewToggle>
        <div
          className={activeView == 0 ? "active" : ""}
          onClick={() => setActiveView(0)}
        >
          Online Support
        </div>
        <div
          className={activeView == 1 ? "active" : ""}
          onClick={() => setActiveView(1)}
        >
          Grief Books
        </div>
      </ViewToggle>
      <ResourceSection>
        {activeView == 0 && (
          <ul>
            <li>
              <a target="_blank" href="https://whatsyourgrief.com/">
                What's Your Grief?
              </a>
              <div>
                Grief education, exploration and expression in practical and
                creative ways.
              </div>
            </li>
            <li>
              <a target="_blank" href="https://www.thedinnerparty.org/">
                The Dinner Party
              </a>
              <div>For 20-and 30-somethings to find community support.</div>
            </li>
            <li>
              <a target="_blank" href="https://modernloss.com/">
                Modern Loss
              </a>
              <div>Candid conversations about grief.</div>
            </li>
            <li>
              <a target="_blank" href="https://www.griefanonymous.com/">
                Grief Anonymous
              </a>
              <div>
                A national support organization for anyone grieving the loss of
                a loved one.
              </div>
            </li>
          </ul>
        )}
        {activeView == 1 && (
          <>
            <BookImage src={books} />
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.harpercollins.com/products/its-okay-to-laugh-nora-mcinerny-purmort?variant=32208028925986"
                >
                  It's Okay to Laugh: (Crying Is Cool Too)
                </a>
                <div>by Nora McInerny Purmort</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Its-That-Youre-Not-Understand/dp/1622039076"
                >
                  It’s OK That You’re Not OK
                </a>
                <div>by Megan Devine</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.simonandschuster.com/books/The-Hot-Young-Widows-Club/Nora-McInerny/TED-Books/9781982109981"
                >
                  {" "}
                  The Hot Young Widows Club
                </a>
                <div>by Nora McInerny</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Understanding-Your-Grief-Essential-Touchstones/dp/1879651351"
                >
                  Understanding Your Grief: 10 Essential Touchstones for Finding
                  Hope &#38; Healing Your Heart
                </a>
                <div>by Alan Wolfelt, Ph.D.</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Healing-Parents-Grieving-Heart-Practical-ebook/dp/B0087GZ6IU"
                >
                  Healing A Parent's Grieving Heart
                </a>
                <div>by Alan Wolfelt, Ph.D.</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/When-Faces-Grief-Know-Grieving/dp/1885933274"
                >
                  When A Man Faces Grief
                </a>
                <div>by Thomas R. Golden and James E. Miller</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.com/Permission-Thrive-Journey-Grief-Growth-ebook/dp/B07LDZK645"
                >
                  Permission to Thrive: My Journey from Grief to Growth
                </a>
                <div>by Susan Angel Miller</div>
              </li>
              <li>
                <a target="_blank" href="https://www.amazon.com/dp/B019MMUAHE">
                  There Is No Good Card For This
                </a>
                <div>by Kelsey Crowe, Ph.D. and Emily McDowell</div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.barnesandnoble.com/w/option-b-sheryl-sandberg/1125138736"
                >
                  Option B: Facing Adversity, Building Resilience, and Finding
                  Joy
                </a>
                <div>by Sheryl Sandberg and Adam Grant</div>
              </li>
            </ul>
          </>
        )}
      </ResourceSection>
      <OtherResources>
        <h2>Other Resources</h2>
        <OtherResourceLinks>
          <a onClick={() => history.push("/services/grief_support/children")}>
            Families / Teens / Children
          </a>
          <a onClick={() => history.push("/services/grief_support/counseling")}>
            Grief Counseling
          </a>
        </OtherResourceLinks>
      </OtherResources>
    </>
  );
};

const GriefSupportMain = () => {
  const history = useHistory();
  return (
    <>
      <HeroDesc>
        We are dedicated to helping loved ones cope with the pain and isolation
        of grief. Each person experiences grief in a unique way. Therefore, we
        offer a variety of resources, including websites, books, and therapists.
        For more information, please{" "}
        <PhoneSpan onClick={() => history.push("/contact")}>
          contact us.
        </PhoneSpan>
      </HeroDesc>
      <ServicesCard>
        <h2>General Resources</h2>
        <img
          src={griefGeneral}
          onClick={() => history.push("/services/grief_support/general")}
        />
        <ServicesButton
          onClick={() => history.push("/services/grief_support/general")}
        >
          Learn More
        </ServicesButton>
      </ServicesCard>
      <ServicesCard>
        <h2>Families/Teens/Children</h2>
        <img
          src={griefKids}
          onClick={() => history.push("/services/grief_support/children")}
        />
        <ServicesButton
          onClick={() => history.push("/services/grief_support/children")}
        >
          Learn More
        </ServicesButton>
      </ServicesCard>
      <ServicesCard>
        <h2>Grief Counseling</h2>
        <img
          src={griefCounseling}
          onClick={() => history.push("/services/grief_support/counseling")}
        />
        <ServicesButton
          onClick={() => history.push("/services/grief_support/counseling")}
        >
          Learn More
        </ServicesButton>
      </ServicesCard>
    </>
  );
};

const GriefSupport = () => {
  const history = useHistory();
  return (
    <>
      <ServicesBanner img={griefSupport}>
        <h1>Grief Support</h1>
      </ServicesBanner>
      <GriefSection>
        <Switch>
          <Route
            path="/services/grief_support/counseling"
            component={GriefSupportCounseling}
          />
          <Route
            path="/services/grief_support/children"
            component={GriefSupportKids}
          />
          <Route
            path="/services/grief_support/general"
            component={GriefSupportGeneral}
          />
          <Route component={GriefSupportMain} />
        </Switch>
      </GriefSection>
      <CTACard>
        <h2>Need more support?</h2>
        <div>
          We are here to help. <br />
          Please reach out for more information.
        </div>
        <GreenButton onClick={() => history.push("/contact")}>
          Contact Us
        </GreenButton>
      </CTACard>
    </>
  );
};

export default GriefSupport;
