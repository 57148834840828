import React from 'react';
import styled from 'styled-components';
import {
	Banner,
	HeroDesc,
	colors,
	fonts,
	StandardButton,
	DownloadButton,
	sizes,
} from '../styles/reusableStyles';
import immediateNeed from '../media/immediateNeed.png';

const ImmediateNeedBanner = styled(Banner)`
	background: ${({ img }) =>
		`linear-gradient(0deg, rgba(73, 77, 73, 0.2), rgba(73, 77, 73, 0.2)), url(${img})`};
	background-size: cover;
	background-position: 0 60%;
	h1 {
		color: white;
	}
`;
const DesktopFlex = styled.div`
	@media screen and (min-width: ${sizes.tablet}) {
		display: flex;
		justify-content: space-evenly;
	}
`;
const LetUsHelpCard = styled.div`
	box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.1);
	border-radius: 12px;
	background: white;
    padding: 16px;
    text-align: center;
	margin: 0 32px 40px 32px;
	max-width: 772px;
	@media screen and (min-width: ${sizes.tablet}){
		width: 80%;
		margin: 0 auto 40px auto;
	}
    
    h2{
        color: ${colors.primaryGreen};
        height: 40px;
        line-height: 40px;
        margin-bottom: 8px;
    }
    div{
        ${fonts.bigBody}
        color: ${colors.smallGreyText};
		margin-bottom: 16px;
		@media screen and (min-width: ${sizes.tablet}){
			max-width: 450px;
			margin: auto;
		}
        span{
            font-weight: bold;
			color: ${colors.primaryOrange};

			&.card-phone{
				display: none;
				@media screen and (min-width: ${sizes.tablet}){
					display: unset;	
				}
			}
        }
    }
`;

const ContactUsButton = styled(StandardButton)`
	background: ${colors.primaryOrange};
	border: 2px solid ${colors.primaryOrange};
	display: inline-block;
	line-height: 28px;

	&:hover {
		color: white;
	}
	@media screen and (min-width: ${sizes.tablet}) {
		display: none;
	}
`;

const InfoSections = styled.div`
	margin: 0 16px;
	margin-bottom: 24px;
	text-align: center;

	h2 {
		color: ${colors.primaryGreen};
		margin-bottom: 8px;
	}
	div {
		${fonts.bigBody};
		color: ${colors.smallGreyText};
		margin-bottom: 24px;
		span {
			font-weight: bold;
		}
	}

	@media screen and (min-width: ${sizes.tablet}) {
		width: 80%;
		margin: 0 auto 40px auto;
		max-width: 772px;
	}
`;

const ImmediateNeed = () => {
	return (
		<div>
			<ImmediateNeedBanner img={immediateNeed}>
				<h1>Immediate Need</h1>
			</ImmediateNeedBanner>
			<LetUsHelpCard>
				<h2>Let us help</h2>
				<div>
					Our experienced staff is here to assist you with compassion,
					dignity and respect.
					<br />
					<br />
					We are available 24/7 including evenings, weekends and
					holidays.
					<br />
					<br />
					<span>Call us immediately.</span>
					<span className='card-phone'>
						<br />
						(414) 964-3111
					</span>
				</div>
				<ContactUsButton as='a' href='tel:(414) 964-3111'>
					Call Us
				</ContactUsButton>
			</LetUsHelpCard>
			<InfoSections>
				<h2>In another city?</h2>
				<div>
					If a death occurs outside of the Milwaukee area, there is no
					need to call upon a funeral home in that city. Please call
					Goodman-Bensman right away, and we'll handle all of the
					arrangements for you.
				</div>
				<h2>Funeral Planning Guide</h2>
				<div>
					Please use this <span>Funeral Planning Guide</span> to
					gather required information for legal documents.
				</div>
				<DesktopFlex>
					<DownloadButton
						as='a'
						href='/Funeral_Planning_Guide.pdf'
						target='_blank'
					>
						Download
					</DownloadButton>
					{/* <DownloadButton
						as='a'
						href='https://forms.gle/eKNTMaaKMQxoh2eA7'
						target='_blank'
					>
						Complete Online
					</DownloadButton> */}
				</DesktopFlex>
			</InfoSections>
		</div>
	);
};

export default ImmediateNeed;
