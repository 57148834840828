import React, { useState } from "react";
import styled from "styled-components";
import logo from "../media/GBLogo.png";
import map from "../media/Map.png";
import phone from "../media/Phone.png";
import mail from "../media/Mail.png";
import desktopBanner from "../media/desktopBanner.png";

import {
  fonts,
  sizes,
  colors,
  FormSubmitButton,
} from "../styles/reusableStyles";
import { Form, Dimmer, Loader } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import mutations from "../helpers/mutations";
import ReCAPTCHA from "react-google-recaptcha";

const FooterContainer = styled.div`
  padding: 16px;
  text-align: center;
  background: white;
  box-shadow: 0px -4px 10px rgba(128, 128, 128, 0.1);
  @media screen and (min-width: ${sizes.tablet}) {
    padding: 40px 48px 24px 48px;
  }
`;

const GBLogo = styled.img`
  width: 15%;
  max-width: 100px;
  margin-bottom: 16px;
  @media screen and (min-width: ${sizes.tablet}) {
    display: none;
  }
`;

const GBDesktopLogo = styled.img`
  width: 60%;
  margin-bottom: 16px;
  display: none;
  @media screen and (min-width: ${sizes.tablet}) {
    display: block;
  }
`;

const Info = styled.div`
  color: ${colors.smallGreyText};
  display: flex;
  align-items: center;
  max-width: 250px;
  margin: auto;
  margin-bottom: 16px;
  width: fit-content;
  @media screen and (min-width: ${sizes.tablet}) {
    margin: 0px;
    margin-bottom: 16px;
    max-width: unset;
  }
`;

const Complete = styled.div`
  color: ${colors.smallGreyText};
  ${fonts.bigBody}
`;

const Copyright = styled.div`
  color: #808080;
  font-family: DM Sans;
  font-style: italic;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.05em;
  padding: 0 15px;
`;

const Icon = styled.img`
  height: ${({ mail }) => (mail ? "16px" : "21px")};
`;
const Text = styled.span`
  ${fonts.bigBody}
  max-width: 300px;
  padding-left: 10px;
  @media screen and (min-width: ${sizes.tablet}) {
    max-width: unset;
  }
`;

const TextLink = styled.a`
  ${fonts.bigBody}
  max-width: 300px;
  padding-left: 10px;
  @media screen and (min-width: ${sizes.tablet}) {
    max-width: unset;
  }
  color: ${colors.smallGreyText};
  &:hover {
    color: ${colors.smallGreyText};
    text-decoration: underline;
  }
`;

const TopSection = styled.div`
  @media screen and (min-width: ${sizes.tablet}) {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #cbcccb;
  }
`;

const LeftSection = styled.div`
  @media screen and (min-width: ${sizes.tablet}) {
    text-align: left;
    flex: 1;
    max-width: 512px;
    margin-left: 36px;
  }
`;

const RightSection = styled.div`
  display: none;
  @media screen and (min-width: ${sizes.tablet}) {
    display: block;
  }
  flex: 1;
  max-width: 512px;
  margin-right: 36px;
  text-align: left;
  margin-left: 10px;

  button {
    margin: unset !important;
  }
`;

const Subtitle = styled.div`
  ${fonts.desktopSubtitle}
`;

const Caption = styled.div`
  ${fonts.desktopCaption}
  margin-bottom: 8px;
`;
const Footer = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);

  const [subscribe, { data, err, loading }] = useMutation(mutations.subscribe);
  const handleSubmit = () => {
    if (!email) {
      setEmailError(true);
      return;
    }
    subscribe({
      variables: {
        email,
      },
    });
  };
  const handleRecaptchaSuccess = (token) => {
    if (token) setCaptchaSuccess(true);
  };
  return (
    <FooterContainer>
      <Dimmer active={loading} />
      <Loader active={loading} />
      <TopSection>
        <LeftSection>
          <GBLogo src={logo} />
          <GBDesktopLogo src={desktopBanner} />
          <Info>
            <Icon src={map} />
            <TextLink
              href="https://www.google.com/maps/place/Goodman-Bensman+Funeral+Home/@43.1032793,-87.9088901,17z/data=!3m1!4b1!4m5!3m4!1s0x88051eeedc08e135:0xa81e5c49edd18ec7!8m2!3d43.1032754!4d-87.9067014"
              target="_blank"
            >
              4750 N. Santa Monica Blvd. Whitefish Bay, WI 53211
            </TextLink>
          </Info>
          <Info>
            <Icon src={phone} />
            <TextLink href="tel:(414) 964-3111">(414) 964-3111</TextLink>
          </Info>
          <Info>
            <Icon mail src={mail} />
            <TextLink href="mailto:info@goodmanbensman.com">
              info@goodmanbensman.com
            </TextLink>
          </Info>
        </LeftSection>
        <RightSection>
          <Subtitle>Stay Connected</Subtitle>
          <Caption>Receive an email when a new obituary is added.</Caption>
          {!(data && data.subscribe) && (
            <Form onSubmit={handleSubmit}>
              <Form.Input
                type="email"
                placeholder="Enter your email address"
                required
                error={emailError}
                onChange={(e) => {
                  setEmailError(false);
                  setEmail(e.target.value);
                }}
              />
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                onChange={handleRecaptchaSuccess}
              />
              {captchaSuccess && (
                <FormSubmitButton type="submit">Submit</FormSubmitButton>
              )}
            </Form>
          )}
          {data && data.subscribe && (
            <Complete>
              Thank you for subscribing! Please check your inbox for an email
              from us asking you to confirm your subscription. If it is not
              there or in your spam folder, please email
              info@goodmanbensman.com.{" "}
            </Complete>
          )}
        </RightSection>
      </TopSection>
      <Copyright>
        ©2020 Milwaukee Funeral Services, LLC dba Goodman-Bensman Whitefish Bay
        Funeral Home
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
